<template>
<div class="container winspection">
  <div class="max">
    <h6>關於逸居 <span>Winspection</span></h6>
    <hr>
    <div class="about-content">
      <p>逸居驗屋團隊是由建築師把關，其驗屋團隊內的驗屋師多為室內建築、機電、室內設計、工程背景所設立的檢驗團隊，採非破壞性的檢測方式，利用現代化的科技儀器，不僅協助客戶找到安全、舒適、節能、健康的好房，亦可在房屋瑕疵擴大前，做完善的房屋健康檢測，並提供給客戶修繕的建議方式，延長房屋的生命週期，擁有一個安心的窩。</p>
    </div>
    <div class="pro-block">
      <div class="pro">
        <h6>專業能力</h6>
        <div class="icon-block">
          <div class="pro-icon">
            <img src="../../assets/ability.png" alt="">
          </div>
        </div>
      </div>
      <div class="pro box-center">
        <h6>專業設備</h6>
        <div class="icon-block  pro-center">
          <div class="pro-icon">
            <img src="../../assets/equipment.png" alt="">
          </div>
        </div>
      </div>
      <div class="pro">
        <h6>專業服務</h6>
        <div class="icon-block">
          <div class="pro-icon">
            <img src="../../assets/service.png" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="spirit">
      <h6>逸居的<span>『 品牌精神 』</span></h6>
      <p class="small-title">- Brand Spirit -</p>
      <p class="content">逸居前端科技檢驗，秉持最專業、用心、值得信賴的態度，來為每位驗屋消費者把關。<br>品牌LOGO的雙勾即表示著逸居用心將每位客戶的房子都進行double check後，再交至客戶手中，協助客戶取得心目中的好房，不讓買屋這個夢想，變成日後修繕、甚至與建商訴訟的惡夢！</p>
    </div>
  </div>
</div>
</template>
<style lang="scss" scoped>
.winspection{
  @include basic-flex(column);
  .max{
    max-width: 1500px;
    @include basic-flex(column);
    h6{
      @include h6(#333);
      @include m-spacing(10px,0px);
      span{
        color: rgba(180,146,106);
      }
    }
    hr{
      width: 50px;
      border: solid 3px rgba(180,146,106);
      border-radius: 3px;
    }
    .about-content{
      @include basic-flex(row);
      @include p-spacing(20px,5%);
      @include m-spacing(20px,0%);
      background-color: rgba(180,146,106,.2);
      // background-color: #f4f4f4;
      border-radius: 8px;
      width: 80%;
      // border: solid 5px rgba(180,146,106,.2);
      p{
        @include m-spacing(10px,10px);
        letter-spacing: 1px;
        width: 100%;
        text-align: left;
        line-height: 25px;
      }
    }
    .pro-block{
      @include basic-flex(row);
      @include m-spacing(40px,0%);
      width: 70%;
      max-width: 900px;
      .pro{
        flex: 30%;
        @include basic-flex(column);
        h6{
          font-size: 17px;
          @include m-spacing(0px,0%);
        }
        .icon-block{
          width: 100%;
          @include basic-flex(column);
          .pro-icon{
            @include basic-flex(column);
            background-color: rgba(180,146,106);
            // border: solid 3px rgba(180,146,106);
            width: 100px;
            height: 100px;
            border-radius: 100%;
            @include m-spacing(20px,0%);
            box-shadow: 0px 0px 0px 8px rgba(180,146,106,0.3);
            img{
              width: 60%;
            }
          }
        }
        .pro-center{
          position: relative;
          &::before{
            content: '';
            background-image: url('../../assets/plus.svg');
            position: absolute;
            width: 30px;
            height: 30px;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            left: 0%;
            top: 50%;
            transform: translate(-50%,-50%);
            opacity: 0.15;
          }
          &::after{
            content: '';
            background-image: url('../../assets/plus.svg');
            position: absolute;
            width: 30px;
            height: 30px;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            right: 0%;
            top: 50%;
            transform: translate(50%,-50%);
            opacity: 0.15;
          }
        }
      }
    }
    .spirit{
      @include basic-flex(column);
      @include m-spacing(10px,0%);
      @include p-spacing(20px,5%);
      background-color: #f4f4f4;
      border-radius: 5px;
      width: 80%;
      h6{
        @include h6(#333);
        span{
          color:rgba(180,146,106);
        }
      }
      .small-title{
        color: #aaa;
      }
      .content{
        @include p(#333);
        @include m-spacing(0px,10px);
        padding-bottom: 20px;
        width: 100%;
        // text-align: center;
      }
    }
  }
}

@media (max-width: 840px){
.winspection{
  .max{
    .about-content{
      width: 90%;
    }
    .pro-block{
      @include basic-flex(column);
      @include m-spacing(10px,0%);
      .pro{
        @include m-spacing(50px, 10%);
        .pro-center{
          &::before, &::after{
            display: none;
          }
        }
      }
      .box-center{
        position: relative;
        &::before{
          content: '';
          background-image: url('../../assets/plus.svg');
          position: absolute;
          width: 40px;
          height: 40px;
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          left: 50%;
          top: 0%;
          transform: translate(-50%,-75px);
          opacity: 0.1;
        }
        &::after{
          content: '';
          background-image: url('../../assets/plus.svg');
          position: absolute;
          width: 40px;
          height: 40px;
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          left: 50%;
          bottom: 0%;
          transform: translate(-50%,60px);
          opacity: 0.1;
        }
      }
    }
    .spirit{
      width: 90%;
      .content{
        width: 90%;
        text-align: left;
      }
    }
  }
}
}
</style>
