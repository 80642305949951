<template>
<div class="container process">
  <div class="max">
    <h6>驗屋流程</h6>
    <div class="process-block">
      <div class="step">
        <div class="number">
          <p>01</p>
        </div>
        <hr>
        <p class="item">聯繫諮詢</p>
        <p class="extra">( 電話/FB粉專/LINE )</p>
      </div>
      <div class="step">
        <div class="number">
          <p>02</p>
        </div>
        <hr>
        <p class="item">確認預約</p>
        <p class="extra">( 預約表單/訂金匯款 )</p>
      </div>
      <div class="step">
        <div class="number">
          <p>03</p>
        </div>
        <hr>
        <p class="item">到場驗屋</p>
        <p class="extra">( 現況驗收檢測 )</p>
      </div>
      <div class="step">
        <div class="number">
          <p>04</p>
        </div>
        <hr>
        <p class="item">驗屋報告</p>
        <p class="extra">( 電子檔 )</p>
      </div>
    </div>
  </div>
</div>
</template>
<style lang="scss" scoped>
.process{
  @include basic-flex(column);
  .max{
    width: 90%;
    max-width: 1500px;
    @include basic-flex(column);
    h6{
      @include h6(#333);
    }
    .process-block{
      @include basic-flex(row);
      @include m-spacing(20px,0px);
      width: 88%;
      align-items: flex-start;
      .step{
        @include basic-flex(column);
        @include m-spacing(2%,2%);
        flex: 16%;
        hr{
          @include m-spacing(20px,5%);
          width: 80%;
          border: dashed 2px #ddd;
          border-radius: 5px;
        }
        .number{
          @include basic-flex(column);
          @include m-spacing(10px,0%);
          background-color: rgba(180,146,106,0.5);
          width: 80px;
          height: 80px;
          border-radius: 100%;
          position: relative;
          &::after{
            content: '';
            border: solid 3px rgba(180,146,106,0.4);
            width: 120%;
            height: 120%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            border-radius: 100%;
          }
          p{
            @include h6(#333);
            font-size: 30px !important;
          }
        }
        .item{
          @include h6(#333);
        }
        .extra{
          @include p(rgba(180,146,106));
          @include m-spacing(15px,0px);
        }
      }
    }
  }
}

@media (max-width: 1380px) {
  .process{
    .max{
      .process-block{
        flex-wrap: wrap;
        @include m-spacing(40px,0px);
        .step{
          flex: 50%;
          max-width: 320px;
          margin-bottom: 40px !important;
          hr{
            width: 60%;
          }
        }
      }
    }
  }
}
</style>
