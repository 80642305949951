import { render } from "./Header.vue?vue&type=template&id=0d635223"
import script from "./Header.vue?vue&type=script&lang=js"
export * from "./Header.vue?vue&type=script&lang=js"

import "./Header.vue?vue&type=style&index=0&id=0d635223&lang=scss"
script.render = render

export default script
import QHeader from 'quasar/src/components/header/QHeader.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QDrawer from 'quasar/src/components/drawer/QDrawer.js';
import QList from 'quasar/src/components/item/QList.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QHeader,QToolbar,QToolbarTitle,QBtn,QSpace,QDrawer,QList,QItemLabel,QItem,QItemSection,QIcon});
