<template>
<div class="banner">
  <div class="max">
    <p>舉凡契約文件到實際屋況、工程、設備、結構，逸居驗屋安排各專業項目的細項檢測，全面性的服務讓您對您的房屋有最深度的了解</p>
  </div>
</div>
</template>

<style lang="scss" scoped>
.banner{
  height: 50vh;
  width: 100%;
  background-image: url('../../assets/service-banner.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  &::before{
    content: "";
    position: absolute;
    background-color: rgba(0,0,0,0.5);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .max{
    width: 65%;
    max-width: 1200px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 60px;
    p{
      // margin: 66px 18% 0px 18%;
      color: #fff;
      z-index: 999;
      letter-spacing: 2px;
      font-size: 14px;
      line-height: 30px;
      width: 65%;
      margin: 0;
    }
  }
}
@media (max-width: 760px){
.banner{
  .max{
    width: 80%;
    p{
      width: 100%;
    }
  }
}
}
</style>
<script>
export default {
  name: 'Banner',
  mounted: function () {
  },
  methods: {
  }
}
</script>
