<template>
  <div class="container form">
    <!-- <router-link class="back-btn" :to="`/book-now`">
      <p>➔ 返回預約查詢</p>
    </router-link> -->
    <div class="iframe-block">
      <iframe :src="date.url" width="640" height="560" frameborder="0" marginheight="0" marginwidth="0">載入中…</iframe>
    </div>
    <!-- <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScFON-WyFa5-KIOGQ0bN5cvLa11BjBGPlJ6ZWeft5h-bZRXmg/viewform?embedded=true" width="640" height="4000" frameborder="0" marginheight="0" marginwidth="0">載入中…</iframe> -->
  </div>
</template>
<style lang="scss" scoped>
.form{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px !important;
  margin-top: 10px;
  width: 100%;
  .back-btn{
    margin: 10px 0%;
    background-color: #000;
    border-radius: 3px;
    display: inline-block;
    text-decoration: none;
    transition-duration: .5s;
    &:hover{
      background-color: #B4926A;
    }
    p{
      margin: 0;
      padding: 8px 15px;
      color: #fff;
      letter-spacing: 1px;
    }
  }
  .iframe-block{
    display: flex;
    justify-content: center;
    margin-top: 10px;
    background-color: #f4f4f4;
    padding: 30px 0px;
    width: 100%;
    max-width: 900px;
    iframe{
      width: 90%;
      height: 650px;
    }
  }
}
// @media(max-width: 780px) {
//   .form{
//     iframe{
//       width: 300px !important;
//     }
//   }
// }
</style>
<script>
import { useMeta } from 'vue-meta'
export default {
  name: 'Form',
  components: {
  },
  data () {
    return {}
  },
  computed: {
    date () {
      const year = this.$store.state.date.split('/')[0]
      const month = this.$store.state.date.split('/')[1]
      const day = this.$store.state.date.split('/')[2]
      const area = this.$store.state.area
      const time = this.$store.state.time
      let timeValue = ''
      if (time === '上午場(9:00開始)') {
        timeValue = '上午場 (9:00開始)'
      } else if (time === '下午場(13:30開始)') {
        timeValue = '下午場 (13:30開始)'
      } else if (time === '整天(9:00開始)') {
        timeValue = '整天 (9:00開始)'
      }
      const url = `https://docs.google.com/forms/d/e/1FAIpQLScFON-WyFa5-KIOGQ0bN5cvLa11BjBGPlJ6ZWeft5h-bZRXmg/viewform?embedded=true&entry.1538889137_year=${year}&entry.1538889137_month=${month}&entry.1538889137_day=${day}&entry.752622090=${timeValue}`
      // const url = `https://docs.google.com/forms/d/e/1FAIpQLSeGwiGSG4ToQ8NSyOtqZFps9PSTjF0nGNUhaxbrr-B4Pi-LIg/viewform?embedded=true&entry.891228020=${area}&entry.670549323_year=${year}&entry.670549323_month=${month}&entry.670549323_day=${day}&entry.944539832=${timeValue}`
      return {
        year,
        month,
        day,
        area,
        time,
        timeValue,
        url
      }
    }
  },
  created () {
    useMeta({ title: '預約服務' })
  },
  mounted () {
    // 在組件掛載後調用 _lt，以確保 _lt 已經在全局範圍內可用
    this.sendConversion()
  },
  methods: {
    sendConversion () {
      if (typeof _lt === 'function') {
        // eslint-disable-next-line no-undef
        _lt('send', 'cv', { type: 'Conversion' }, ['839fe146-11a4-4a86-a0b1-bf0706af14e3'])
      } else {
        console.error('_lt function is not defined')
      }
    }
  }
}
</script>
