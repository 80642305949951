/**
 * @typedef {import('axios').AxiosResponse} AxiosResponse
 *
 * @typedef {Object} ApiResData API 回應內容
 * @property {string} status 狀態。suc、warn、err
 * @property {string} msg 訊息
 * @property {*} data 資料內容
 *
 * @typedef {Object} TimestampObj 時間戳記
 * @property {number} createdAt 建立時間
 * @property {number | null} editedAt 最後編輯時間
 * @property {number | null} deletedAt 軟刪除時間
 *
 * @typedef {Object} Post 文章資料
 * @property {string} _uid 資料 ID
 * @property {Object} setting 文章設定
 * @property {boolean} setting.isShow 是否在前台顯示
 * @property {number} releaseDate 發布時間，Unix Timestamp
 * @property {string} title 標題
 * @property {string} summary 摘要
 * @property {string} cover 封面圖片
 * @property {string} text 內文
 * @property {string} postUrl 文章連結
 * @property {TimestampObj} timestamp 時間戳記
 *
 *
 * @typedef {Object} GetPostsResult
 * @property {number} offset 偏移量
 * @property {number} limit 最大數量
 * @property {number} total 文章總數量
 * @property {Post[]} posts 文章資料
 */

import axios from 'axios'

const reqObj = axios.create({
  baseURL: '/api',
  headers: {
    'Content-Type': 'application/json'
  },
  timeout: 4000
})

/** 包裝、處理 axios 請求 */
function handleReq (p) {
  return new Promise((resolve, reject) => {
    p
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  })
}

/** 取得文章
  *
  * 取得指定數量與分頁之文章內容。
  *
  * @param {number} offset 偏移量，預設 0，作為分頁功能使用。
  * @param {number} limit 最大數量，預設 10，取得文章之最大數量。
  * @return {Promise<GetPostsResult>}
  */
export function getPosts (offset = 0, limit = 9999) {
  return handleReq(reqObj.get('/v1/posts', {
    params: {
      offset, limit
    }
  }))
}

/** 根據 URL 取得指定文章
  *
  * 每一篇文章皆有專屬 URL，可以根據 URL 取得指定文章。
  *
  * @param {string} url 偏移量，預設 0，作為分頁功能使用。
  * @return {Promise<GetPostsResult>}
  */
export function getPostByUrl (url = '') {
  return handleReq(reqObj.get('/v1/post', {
    params: {
      url
    }
  }))
}

// 取得Google評論
export function getComments (offset = 0, limit = 9999) {
  return handleReq(reqObj.get('/v1/comment-google-map', {
    params: {
      offset, limit
    }
  }))
}

// 取得案例
export function getCases (offset = 0, limit = 9999) {
  return handleReq(reqObj.get('/v1/cases', {
    params: {
      offset, limit
    }
  }))
}

// 根據 URL 取得指定案例
export function getCaseByUrl (url = '') {
  return handleReq(reqObj.get('/v1/case', {
    params: {
      url
    }
  }))
}

// 取得預約資料
export function getBookData (month = '') {
  return handleReq(reqObj.get('/v1/reserve/month', {
    params: {
      month
    }
  }))
}

// 取得跳動數字資料
export function getSetting () {
  return handleReq(reqObj.get('/v1/setting'))
}
