<template>
<div class="container intro">
  <div class="max">
    <h6>建商交屋｜中古屋買賣｜房屋健檢｜全台服務｜驗屋推薦</h6>
    <div class="why">
      <h6>為何需要專業驗屋 ?</h6>
      <div class="slice-two">
        <div class="left">
          <img src="../../assets/office.jpg" alt="">
        </div>
        <div class="right">
          <p>現今的不動產交易動輒數百、千萬，但由於房屋的資訊不對等，常常消費者花了大錢，住進新屋後，才發現原來屋內東漏西漏，甚至來個霸王級寒流，屋內磁磚也冷到全部開花(澎共爆裂)，甚至嚴重的如當初建商及工程單位為了省事、省錢，屋內電線線徑自動降了一階，大電量電器使用處應設置的專迴也省了，如此的房子住起來實在叫人膽顫心驚。
            <br><br>而逸居檢驗團隊，就是利用百萬級的高科技設備儀器，並且依照合約內容、圖說、施工規範、建築、機電、消防等相關法規，幫客戶找出這些潛在的未知缺陷，並提供客戶修繕建議，協助客戶在交屋時，能更全面的了解屋況！減少後續的修繕、爭議問題產生。
          </p>
          <a class="a-btn" href="/shit-happened">常見缺失</a>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<style lang="scss">
.intro{
  @include basic-flex(column);
  .max{
    max-width: 1500px;
    @include basic-flex(column);
    h6{
      @include h6(#333);
      @include m-spacing(20px,0px);
    }
    .why{
      h6{
        @include h6(#333);
        @include m-spacing(10px,0px);
      }
      .slice-two{
        @include basic-flex(row);
        @include m-spacing(20px,0px);
        .left,.right{
          flex: 1;
          @include m-spacing(10px,20px);
        }
        .left{
          border-radius: 5px;
          overflow: hidden;
          height: 90%;
          img{
            width: 100%;
          }
        }
        .right{
          @include p(#000);
          .a-btn{
            @include a-btn(#333,none,#fff);
            @include m-spacing(10px,0px);
          }
        }
      }
    }
  }
}

@media (max-width: 1140px){
  .intro{
    .max{
      .why{
        .slice-two{
          @include basic-flex(column);
        }
      }
    }
  }
}
</style>
