import { render } from "./Progress.vue?vue&type=template&id=4a4236a2&scoped=true"
import script from "./Progress.vue?vue&type=script&lang=js"
export * from "./Progress.vue?vue&type=script&lang=js"

import "./Progress.vue?vue&type=style&index=0&id=4a4236a2&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-4a4236a2"

export default script
import QPage from 'quasar/src/components/page/QPage.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QPage});
