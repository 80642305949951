import { render } from "./Comments.vue?vue&type=template&id=4b0ac271&scoped=true"
import script from "./Comments.vue?vue&type=script&lang=js"
export * from "./Comments.vue?vue&type=script&lang=js"

import "./Comments.vue?vue&type=style&index=0&id=4b0ac271&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-4b0ac271"

export default script
import QCarousel from 'quasar/src/components/carousel/QCarousel.js';
import QCarouselSlide from 'quasar/src/components/carousel/QCarouselSlide.js';
import QRating from 'quasar/src/components/rating/QRating.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCarousel,QCarouselSlide,QRating,QBtn});
