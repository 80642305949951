<template>
  <div class="loading">
    <img src="/images/loading.gif">
  </div>
</template>

<style lang="scss" scoped>
.loading{
  position: fixed;
  width: 100%;
  height: calc(100vh - 66px);
  background-color: #fff;
  top: 66px;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  img{
    width: 150px;
    height: 150px;
  }
}
</style>
<script>
// import { ref } from 'vue'
export default {
  name: 'Loading',
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
  }
}
</script>
