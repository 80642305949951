<template>
<div class="container lists">
  <div class="max">
    <h6>驗屋服務項目</h6>
    <hr>
    <div class="lists-block">
      <div class="list" v-for="(list, index) in lists" :key="index">
        <div class="left">
          <img :src="list.img" alt="">
        </div>
        <div class="right">
          <div class="name">
            <div class="num">
              <p>{{index+1}}</p>
            </div>
            <h6>{{list.name}}</h6>
          </div>
          <div class="line"></div>
          <ul>
            <li v-for="(item, index) in list.items" :key="index">{{item}}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<style lang="scss" scoped>
.lists{
  @include basic-flex(column);
  .max{
    max-width: 1500px;
    @include basic-flex(column);
    h6{
      @include h6(#333);
      @include m-spacing(10px,0px);
    }
    hr{
      width: 50px;
      border: solid 3px rgba(180,146,106);
      border-radius: 3px;
    }
    .lists-block{
      width: 90%;
      .list{
        margin: 5% 0px;
        @include basic-flex(row);
        .left{
          flex: 1;
          position: relative;
          border-radius: 5px;
          @include m-spacing(0px,6%);
          img{
            width: 100%;
            border-radius: 5px;
          }
        }
        .right{
          flex: 1;
          @include basic-flex(column);
          align-items: flex-start;
          .name{
            display: flex;
            align-items: center;
            .num{
              background-color: rgba(180,146,106);
              width: 30px;
              height: 30px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 100%;
              margin-right: 10px;
              p{
                margin: 0;
                padding: 0;
                color: #fff;
              }
            }
          }
          .line{
            border-top: dashed 3px rgba(180,146,106,.3);
            width: 100%;
            border-radius: 5px;
          }
          ul{
            list-style: none;
            padding: 0;
            li{
              margin: 8px auto;
              letter-spacing: 1px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1360px) {
.lists{
  .max{
    .lists-block{
      .list{
        @include basic-flex(column);
        @include m-spacing(30px,0%);
        .left{
          @include m-spacing(0px,0%);
        }
        .right{
          width: 100%;
          justify-content: flex-start;
          .line{
            width: 100%;
          }
        }
      }
    }
  }
}
}
</style>
<script>
export default {
  name: 'About',
  components: {
  },
  data () {
    return {
      lists: [
        {
          name: '文件相關檢查',
          img: '../images/文件相關檢查.jpg',
          items: ['◉ 核對契約內容(建材、平面圖)', '◉ 室內尺寸丈量(需提供紙本平面圖)(室內隔間後面積，非權狀面積)', '◉ 建築法規核對']
        },
        {
          name: '室內環境檢查',
          img: '../images/室內環境檢查.jpg',
          items: ['◉ 輻射檢測(參考值)', '◉ 室內噪音檢測', '◉ HCHO(甲醛)檢測']
        },
        {
          name: '​屋況檢查',
          img: '../images/屋況檢查.jpg',
          items: ['◉ 屋內排水檢測', '◉ 磁磚檢查', '◉ 紅外線熱像儀滲漏水檢測', '◉ 水分計含水率檢測', '◉ 排水孔內視鏡檢測', '◉ 窗戶滲水檢測', '◉ 天花板、壁面油漆施工品質檢查', '◉ 門、窗、牆面垂直水平檢測']
        },
        {
          name: '​​裝修工程檢測',
          img: '../images/裝修工程檢測.jpg',
          items: ['◉ 地坪收邊檢視', '◉ 壁紙平整度檢視', '◉ 石材表面完整度檢查', '◉ 地、壁磚檢測', '◉ 窗簾、裱布表面完整度檢查', '◉ 櫥櫃五金功能檢測', '◉ 室內櫃體、門片垂直水平檢測', '◉ 其他室內裝修施工品質檢查']
        },
        {
          name: '​​設備、排水測試',
          img: '../images/設備、排水測試.jpg',
          items: ['◉ 水壓測試', '◉ 管道間檢查', '◉ 浴室設備功能檢查', '◉ 浴室地坪洩水檢測', '◉ 淋浴間防水測試', '◉ 排風設備管路檢查']
        },
        {
          name: '​​機電相關檢測',
          img: '../images/機電相關檢測.jpg',
          items: ['◉ 電力相關檢測(接線、插座、配電盤等)', '◉ 弱電相關檢測(電視、網路、電話等)']
        },
        {
          name: '​​結構安全檢視',
          img: '../images/結構安全檢視.jpg',
          items: ['◉ 主要結構裂縫目視判定(實際耐震評估需另請專業團體檢測)']
        }
      ]
    }
  },
  methods: {}
}
</script>
