<template>
<div class="container related-company">
    <h6>逸居的相關企業</h6>
    <div class="related-block">
        <div class="related">
            <div class="related-logo">
                <img src="../../assets/築辰.png">
            </div>
            <p>築辰建築師事務所</p>
        </div>
        <div class="related">
            <div class="related-logo">
                <img src="../../assets/舞夏.svg">
            </div>
            <p>舞夏室內空間設計</p>
        </div>
    </div>
    <div class="concatenate">
        <div class="concatenate-item">
            <div class="item">
                <h6>建築設計</h6>
            </div>
        </div>
        <div class="concatenate-item concatenate-center">
            <div class="item">
                <h6>驗屋服務</h6>
            </div>
        </div>
        <div class="concatenate-item">
            <div class="item">
                <h6>室內設計</h6>
            </div>
        </div>
    </div>
    <div class="link-to">
        <h6>如有設計需求，請至舞夏設計網站</h6>
        <a href="https://www.woosha-design.com">https://www.woosha-design.com</a>
    </div>
</div>
</template>
<style lang="scss" scoped>
.related-company{
    @include basic-flex(column);
    h6{
        @include h6(#333);
        @include m-spacing(10px,0px);
    }
    .related-block{
        @include basic-flex(row);
        width: 70%;
        align-items: stretch;
        .related{
            @include basic-flex(column);
            .related-logo{
                height: 100%;
                @include basic-flex(column);
                @include m-spacing(20px,30px);
                @include p-spacing(20px,20px);
                background-color: #f4f4f4;
                border-radius: 5px;
                border: solid 5px rgba(180,146,106,0.2);
                img{
                    display: block;
                    width: 200px;
                }
            }
            p{
                @include p(#333);
                @include m-spacing(20px,0px);
            }
        }
    }
    .concatenate{
        @include basic-flex(row);
        @include m-spacing(100px,10%);
        width: 76%;
        max-width: 900px;
        .concatenate-item{
            @include basic-flex(column);
            width: 30%;
            .item{
                @include basic-flex(column);
                border-radius: 100%;
                background-color: #B4926A;
                box-shadow: 0px 0px 0px 10px rgba(180,146,106,0.2);
                width: 120px;
                height: 120px;
                h6{
                    color: #fff;
                    font-size: 18px;
                }
            }
        }
        .concatenate-center{
            position: relative;
            &::before{
                content: '';
                background-image: url('../../assets/arrow.png');
                position: absolute;
                width: 40px;
                height: 40px;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                top: 50%;
                left: 0px;
                transform: translate(-50%,-50%);
                opacity: 0.2;
            }
            &::after{
                content: '';
                background-image: url('../../assets/arrow.png');
                position: absolute;
                width: 40px;
                height: 40px;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                top: 50%;
                right: 0px;
                transform: translate(50%,-50%);
                opacity: 0.2;
            }
        }
    }
    .link-to{
        @include basic-flex(column);
        margin-bottom: 50px;
        a{
            text-decoration: none;
            color: #B4926A;
            font-size: 18px;
        }
    }
}

@media(max-width: 1100px){
.related-company{
    .related-block{
        @include basic-flex(column);
        .related{
            .related-logo{
                height: 150px;
            }
        }
    }
    .concatenate{
        @include basic-flex(column);
        @include m-spacing(10px,0px);
        .concatenate-item{
            @include m-spacing(50px,0px);
        }
        .concatenate-center{
            &::before{
                top: 0px;
                left: 50%;
                transform: translate(-50%,-70px) rotate(90deg);
            }
            &::after{
                bottom: 0px;
                left: 50%;
                transform: translate(-50%,95px) rotate(90deg)
            }
        }
    }
}
}
@media (max-width: 760px){
.related-company{
    .link-to{
        h6{
            font-size: 16px;
        }
        a{
            font-size: 15px;
        }
    }
}
}
</style>
