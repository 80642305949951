<template>
  <div class="expansion">
    <!-- <p class="text">
      {{ textList.show }}<span v-if="textList.more"> ...</span><span v-if="!textList.more">{{ textList.hide }}</span>
    </p> -->
    <div class="text">
      <span v-html="textList.show"></span>
      <span v-if="textList.more"> ...</span>
      <span v-html="textList.hide" v-if="!textList.more"></span>
    </div>
    <!-- <div v-html="textList.show"></div> -->
    <p class="action-btn" v-if="textList.more" @click="action()">展開</p>
    <p class="action-btn" v-if="textList.less" @click="action()">收合</p>
  </div>
</template>
<style lang="scss">
.expansion{
  margin-top: 15px;
  .text{
    margin: 0;
    letter-spacing: 1px;
    a{
      color: #b4926a;
    }
  }
  .action-btn{
    display: inline-block;
    padding: 3px 0px;
    margin-top: 5px;
    letter-spacing: 3px;
    color: #b4926a;
    border-bottom: solid 2px #b4926a;
    cursor: pointer;
  }
}
</style>
<script>
export default {
  name: 'Expansion',
  props: ['parentMsg'],
  data () {
    return {
      isShow: true
    }
  },
  computed: {
    textList () {
      const that = this
      const reg = /<\/?.+?\/?>/g
      const msg = this.parentMsg.replace(reg, '')
      if (msg.length > 40) {
        return {
          show: this.parentMsg.substring(0, 40) + '</span>',
          hide: '<span>' + this.parentMsg.substr(40),
          more: that.isShow,
          less: !that.isShow
        }
      } else {
        return {
          show: this.parentMsg,
          hide: '',
          more: false,
          less: false
        }
      }
    }
  },
  methods: {
    action () {
      this.isShow = !this.isShow
    }
  }
}
</script>
