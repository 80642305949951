import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import VueGtag from 'vue-gtag'
import { createMetaManager } from 'vue-meta'
import axios from 'axios'
import VueAxios from 'vue-axios'

import './styles/ck-editor.scss'

createApp(App).use(Quasar, quasarUserOptions).use(store).use(VueAxios, axios).use(router).use(createMetaManager()).use(VueGtag, {
  config: {
    id: 'UA-140910273-1'
  }
}, router).mount('#app')
