import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Service from '../views/Items.vue'
import Cost from '../views/Spend.vue'
import Common from '../views/Common.vue'
import Progress from '../views/Progress.vue'
import Area from '../views/Area.vue'
import Case from '../views/Case.vue'
// import Blog from '../views/Blog.vue'
import Blog2 from '../views/Blog2.vue'
import Post from '../views/Post.vue'
// import Book from '../views/Book.vue'
import Booking from '../views/Booking.vue'
import Form from '../views/Form.vue'
import NotFound from '../views/404.vue'
// import { gsap } from 'gsap'
// import { ScrollTrigger } from 'gsap/ScrollTrigger'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about-winspection',
    name: 'About',
    component: About
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/we-do-all-of-this',
    name: 'Service',
    component: Service
  },
  {
    path: '/cost',
    name: 'Cost',
    component: Cost
  },
  {
    path: '/shit-happened',
    name: 'Common',
    component: Common
  },
  {
    path: '/progress',
    name: 'Progress',
    component: Progress
  },
  {
    path: '/service-area',
    name: 'Area',
    component: Area
  },
  {
    path: '/winspection-blog',
    name: 'Blog2',
    component: Blog2
  },
  {
    path: '/book-now',
    name: 'Booking',
    component: Booking,
    redirect: {
      name: 'form'
    },
    children: [
      // {
      //   path: '',
      //   component: Book
      // },
      {
        path: 'form',
        name: 'form',
        component: Form
      }
    ]
  },
  {
    path: '/:caseId',
    name: 'Case',
    component: Case
  },
  // {
  //   path: '/:pathMatch(.*)*',
  //   name: 'NotFound',
  //   component: NotFound
  // },
  {
    path: '/post/:postId',
    name: 'Post',
    component: Post
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, saveTop) {
    console.log('🚀 ~ file: index.js:108 ~ scrollBehavior ~ to:', to)
    // if (saveTop) {
    //   return saveTop
    // } else {
    //   return { left: 0, top: 0 }
    // }
    return { top: 0 }
    // window.scrollTo(0, 0)
  }
})

router.beforeEach((to, from) => {
  if (!from.name && (to.params.caseId === '0626' || to.params.caseId === '0606')) {
    router.replace('/')
  }
})

export default router
