import { render } from "./Blog2.vue?vue&type=template&id=2563b05a&scoped=true"
import script from "./Blog2.vue?vue&type=script&lang=js"
export * from "./Blog2.vue?vue&type=script&lang=js"

import "./Blog2.vue?vue&type=style&index=0&id=2563b05a&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-2563b05a"

export default script
import QPage from 'quasar/src/components/page/QPage.js';
import QResponsive from 'quasar/src/components/responsive/QResponsive.js';
import QPagination from 'quasar/src/components/pagination/QPagination.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QPage,QResponsive,QPagination});
