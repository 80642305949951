<template>
  <div class="container example">
    <div class="max">
      <h6>服務案例</h6>
      <hr />
      <div class="intro">
        <p>全台皆有檢驗服務案例<br />驗屋交給逸居團隊，專業經驗，值得信賴</p>
      </div>
      <div class="case-content">
        <div class="taiwan-area" v-for="(item, index) in cases" :key="index">
          <h6>{{ item.location }}</h6>
          <div class="area">
            <div class="case" v-for="(list, index) in item.case" :key="index">
              <div class="inner">
                <router-link class="inner-bg" :to="`/${list.url}`">
                  <h6>{{ list.title }}</h6>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Loading v-if="loading" />
</template>
<style lang="scss" scoped>
.example {
  @include basic-flex(column);
  .max{
    max-width: 1500px;
    @include basic-flex(column);
    h6 {
      @include h6(#333);
      @include m-spacing(10px,0px);
    }
    hr {
      width: 50px;
      border: solid 3px rgba(180, 146, 106);
      border-radius: 3px;
    }
    .intro {
      @include p-spacing(10px, 5%);
      @include m-spacing(0px, 0%);
      p {
        @include p(#333);
        @include m-spacing(10px, 10px);
        text-align: center;
        line-height: 30px;
      }
    }
    .case-content {
      @include m-spacing(20px, 0%);
      width: 80%;
      .taiwan-area {
        @include basic-flex(column);
        width: 100%;
        margin-bottom: 40px;
        .area {
          @include basic-flex(row);
          justify-content: flex-start;
          flex-wrap: wrap;
          width: 100%;
          margin-top: 10px;
          .case {
            width: 25%;
            .inner {
              @include m-spacing(10px, 1%);
              border-radius: 15px 15px 15px 0px;
              width: 100%;
              .inner-bg {
                @include basic-flex(column);
                @include m-spacing(10px, 10px);
                @include p-spacing(15px, 30px);
                background-color: rgba(180, 146, 106, 0.5);
                border-radius: 10px 10px 10px 0px;
                text-decoration: none;
                h6 {
                  @include p(#333);
                  @include m-spacing(0px, 0%);
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1100px) {
  .example {
    .max{
      .case-content {
        .taiwan-area {
          .area {
            .case {
              width: 25%;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 870px) {
  .example {
    .max{
      .case-content {
        .taiwan-area {
          .area {
            .case {
              width: 33.33%;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 650px) {
  .example{
    .max{
      .case-content {
        width: 100%;
        .taiwan-area {
          .area {
            .case {
              width: 50%;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 440px) {
  .example {
    .max{
      .case-content {
        width: 100%;
        .taiwan-area {
          .area {
            .case {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
</style>
<script>
import { getCases } from '@/api/api'
import Loading from '@/components/Loading.vue'

export default {
  components: {
    Loading
  },
  data () {
    return {
      loading: true,
      cases: [
        {
          location: '北部地區',
          case: []
        },
        {
          location: '中部地區',
          case: []
        },
        {
          location: '南部地區',
          case: []
        }
      ]
    }
  },
  methods: {
    apiStart () {
      getCases().then((result) => {
        console.log('[ getCases ] result : ', result)
        const filterCases = result.list.filter(e => e.setting.isShow === true)
        // 將北部地區整理進陣列
        const taipei = filterCases.filter(e => e.title.includes('台北'))
        const newTaipei = filterCases.filter(e => e.title.includes('新北'))
        const keelung = filterCases.filter(e => e.title.includes('基隆'))
        const taoyuan = filterCases.filter(e => e.title.includes('桃園'))
        const hsinchu = filterCases.filter(e => e.title.includes('新竹'))
        const yilan = filterCases.filter(e => e.title.includes('宜蘭'))
        this.cases.forEach(element => {
          if (element.location === '北部地區') {
            taipei.forEach(j => {
              element.case.push(j)
            })
            newTaipei.forEach(j => {
              element.case.push(j)
            })
            keelung.forEach(j => {
              element.case.push(j)
            })
            taoyuan.forEach(j => {
              element.case.push(j)
            })
            hsinchu.forEach(j => {
              element.case.push(j)
            })
            yilan.forEach(j => {
              element.case.push(j)
            })
          }
        })

        // 將中部地區整理進陣列
        const taichung = filterCases.filter(e => e.title.includes('台中'))
        const miaoli = filterCases.filter(e => e.title.includes('苗栗'))
        const changhua = filterCases.filter(e => e.title.includes('彰化'))
        const nantou = filterCases.filter(e => e.title.includes('南投'))
        const yunlin = filterCases.filter(e => e.title.includes('雲林'))
        this.cases.forEach(element => {
          if (element.location === '中部地區') {
            taichung.forEach(j => {
              element.case.push(j)
            })
            miaoli.forEach(j => {
              element.case.push(j)
            })
            changhua.forEach(j => {
              element.case.push(j)
            })
            nantou.forEach(j => {
              element.case.push(j)
            })
            yunlin.forEach(j => {
              element.case.push(j)
            })
          }
        })

        // 將南部地區整理進陣列
        const chiayi = filterCases.filter(e => e.title.includes('嘉義'))
        const tainan = filterCases.filter(e => e.title.includes('台南'))
        const kaohsiung = filterCases.filter(e => e.title.includes('高雄'))
        const pingtung = filterCases.filter(e => e.title.includes('屏東'))
        this.cases.forEach(element => {
          if (element.location === '南部地區') {
            chiayi.forEach(j => {
              element.case.push(j)
            })
            tainan.forEach(j => {
              element.case.push(j)
            })
            kaohsiung.forEach(j => {
              element.case.push(j)
            })
            pingtung.forEach(j => {
              element.case.push(j)
            })
          }
        })
        this.loading = false
      }).catch((err) => {
        console.log('[ getCases ] err : ', err.response)
      })
    }
  },
  mounted () {
    this.apiStart()
  }
}
</script>
