import { render } from "./Footer.vue?vue&type=template&id=10b6f569&scoped=true"
import script from "./Footer.vue?vue&type=script&lang=js"
export * from "./Footer.vue?vue&type=script&lang=js"

import "./Footer.vue?vue&type=style&index=0&id=10b6f569&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-10b6f569"

export default script
import QFooter from 'quasar/src/components/footer/QFooter.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QFooter});
