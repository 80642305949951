<template>
  <div>
    <!-- <q-header elevated class="original-bg" :class="{'change-bg': headerBg}"> -->
    <q-header elevated :class="{originalBg: !headerBg}">
      <q-toolbar>
        <q-toolbar-title>
          <router-link to="/">
            <img src="../assets/header-logo.svg" class="gt-sm desktop-logo">
            <img src="../assets/逸居logo.png" class="lt-md mobile-logo">
          </router-link>
        </q-toolbar-title>

        <div class="gt-sm btn-area">
          <router-link to="/"><q-btn flat rounded label="首頁" class="q-mr-sm"></q-btn></router-link>
          <router-link to="/about-winspection"><q-btn flat rounded label="關於我們" class="q-mr-sm"></q-btn></router-link>
          <router-link to="/we-do-all-of-this"><q-btn flat rounded label="服務項目" class="q-mr-sm"></q-btn></router-link>
          <router-link to="/cost"><q-btn flat rounded label="費用標準" class="q-mr-sm"></q-btn></router-link>
          <router-link to="/shit-happened"><q-btn flat rounded label="常見缺失" class="q-mr-sm"></q-btn></router-link>
          <router-link to="/progress"><q-btn flat rounded label="驗屋流程" class="q-mr-sm"></q-btn></router-link>
          <router-link to="/service-area"><q-btn flat rounded label="服務案例" class="q-mr-sm"></q-btn></router-link>
          <router-link to="/winspection-blog"><q-btn flat rounded label="驗屋知識" class="q-mr-sm"></q-btn></router-link>
          <router-link to="/book-now/form"><q-btn flat rounded label="預約服務" class="q-mr-sm"></q-btn></router-link>
          <!-- <q-btn outline rounded label="預約系統"></q-btn> -->
        </div>
        <q-space />
        <div class="lt-md">
          <q-btn flat dense round @click="leftDrawerOpen = !leftDrawerOpen" icon="r_menu" />
        </div>
      </q-toolbar>
    </q-header>
    <q-drawer
      v-model="leftDrawerOpen"
      class="lt-md"
      :width="$q.screen.width"
    >
      <q-list>
        <q-item-label header>
          <router-link to="/"><img src="../assets/逸居logo.png"></router-link>
          <q-space />
          <q-btn dense round @click="leftDrawerOpen = !leftDrawerOpen" icon="r_close" />
        </q-item-label>
        <q-item clickable tag="a" target="_blank" href="/">
          <!-- <q-item-section avatar>
            <q-icon name="r_school" />
          </q-item-section> -->
          <q-item-section>
            <router-link to="/">
              <q-item-label>首頁</q-item-label>
              <q-item-label caption>Home</q-item-label>
            </router-link>
          </q-item-section>
        </q-item>
        <q-item clickable tag="a" target="_blank" href="/about-winspection">
          <!-- <q-item-section avatar>
            <q-icon name="r_code" />
          </q-item-section> -->
          <q-item-section>
            <router-link to="/about-winspection">
              <q-item-label>關於我們</q-item-label>
              <q-item-label caption>About</q-item-label>
            </router-link>
          </q-item-section>
        </q-item>
        <q-item clickable tag="a" target="_blank" href="/we-do-all-of-this">
          <!-- <q-item-section avatar>
            <q-icon name="r_chat" />
          </q-item-section> -->
          <q-item-section>
            <router-link to="/we-do-all-of-this">
              <q-item-label>服務項目</q-item-label>
              <q-item-label caption>Service</q-item-label>
            </router-link>
          </q-item-section>
        </q-item>
        <q-item clickable tag="a" target="_blank" href="/cost">
          <!-- <q-item-section avatar>
            <q-icon name="r_forum" />
          </q-item-section> -->
          <q-item-section>
            <router-link to="/cost">
              <q-item-label>費用標準</q-item-label>
              <q-item-label caption>Cost</q-item-label>
            </router-link>
          </q-item-section>
        </q-item>
        <q-item clickable tag="a" target="_blank" href="/shit-happened">
          <!-- <q-item-section avatar>
            <q-icon name="r_rss_feed" />
          </q-item-section> -->
          <q-item-section>
            <router-link to="/shit-happened">
              <q-item-label>常見缺失</q-item-label>
              <q-item-label caption>Common Missing</q-item-label>
            </router-link>
          </q-item-section>
        </q-item>
        <q-item clickable tag="a" target="_blank" href="https://twitter.com/quasarframework">
          <!-- <q-item-section avatar>
            <q-icon name="r_rss_feed" />
          </q-item-section> -->
          <q-item-section>
            <router-link to="/progress">
              <q-item-label>驗屋流程</q-item-label>
              <q-item-label caption>inspection process</q-item-label>
            </router-link>
          </q-item-section>
        </q-item>
        <q-item clickable tag="a" target="_blank" href="/service-area">
          <!-- <q-item-section avatar>
            <q-icon name="r_rss_feed" />
          </q-item-section> -->
          <q-item-section>
            <router-link to="/service-area">
              <q-item-label>服務案例</q-item-label>
              <q-item-label caption>Case</q-item-label>
            </router-link>
          </q-item-section>
        </q-item>
        <q-item clickable tag="a" target="_blank" href="/winspection-blog">
          <!-- <q-item-section avatar>
            <q-icon name="r_rss_feed" />
          </q-item-section> -->
          <q-item-section>
            <router-link to="/winspection-blog">
              <q-item-label>驗屋知識</q-item-label>
              <q-item-label caption>Blog</q-item-label>
            </router-link>
          </q-item-section>
        </q-item>
        <q-item clickable tag="a" target="_blank" href="/book-now">
          <!-- <q-item-section avatar>
            <q-icon name="r_rss_feed" />
          </q-item-section> -->
          <q-item-section>
            <router-link to="/book-now/form">
              <q-item-label>預約服務</q-item-label>
              <q-item-label caption>Booking</q-item-label>
            </router-link>
          </q-item-section>
        </q-item>
      </q-list>
    </q-drawer>
  </div>
</template>

<style lang="scss">
.q-header{
  position: fixed !important;
  padding: 8px;
  z-index: 99999;
  .q-toolbar{
    height: 50px;
    a{
      color: #fff;
      text-decoration: none;
    }
    .q-toolbar__title{
      display: flex;
      .desktop-logo{
        width: 40px;
        padding: 3px;
      }
      .mobile-logo{
        width: 130px;
        padding: 3px;
        height: 32px;
      }
      a{
        display: flex;
      }
    }
    .btn-area{
      .q-btn{
        letter-spacing: 2px;
        font-weight: 500;
        font-size: 14px;
        padding: 4px 12px ;
      }
    }
  }
}
.q-drawer{
  // width: 100vw !important;
  .q-drawer__content{
    background: rgba(180,146,106,0.7) !important;
    padding: 10px;
    .q-item__section{
      padding: 6px 0px;
      a{
        text-decoration: none;
        color: #000;
        display: inline-block;
        text-align: center;
      }
    }
    .q-item__label--header{
      display: flex;
      justify-content: center;
      align-items: center;
      img{
        width: 170px;
      }
      .q-btn__content{
        border-radius: 100%;
        border: solid 3px #000;
        i{
          color: #000;
          font-weight: 900;
        }
      }
    }
  }
}
.originalBg{
  background-color: transparent !important;
  transition-duration: 0.5s;
}

.changeBg{
  background-color: #B4926A !important;
}
</style>

<script>
// import { gsap } from 'gsap'
import { ref } from 'vue'
export default {
  name: 'Header',
  data () {
    return {
      leftDrawerOpen: ref(false),
      scroll: '',
      headerBg: false
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll () {
      const el = document.scrollingElement || document.documentElement
      this.scroll = el.scrollTop
      if (this.scroll !== 0) {
        this.headerBg = true
      } else {
        this.headerBg = false
      }
    }
  }
}
</script>
