<template>
  <div class="container other">
    <h6>其他費用</h6>
    <p>檢驗地區會依所在地不同而酌收『車馬費用』，費用標準如下：</p>
    <Taiwan />
    <div class="other-content">
      <div class="taiwan-area" v-for="(item, index) in area" :key="index">
        <h6>{{item.location}}</h6>
        <div class="area">
          <div class="separate" v-for="(separate, index) in item.separate" :key="index">
            <div class="inner">
              <div class="inner-bg">
                <h6>{{separate.name}}</h6>
                <p>{{separate.cost}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.other{
  @include basic-flex(column);
  background-color: #f9f9f9;
  h6{
    @include h6(#333);
    @include m-spacing(10px,0px);
  }
  p{
    @include p(#333);
    @include m-spacing(10px,10px);
  }
  .other-content{
    @include m-spacing(20px,0%);
    width: 100%;
    display: none;
    .taiwan-area{
      @include basic-flex(column);
      width: 100%;
      .area{
        display: flex;
        width: 100%;
        .separate{
          width: 33.33%;
          .inner{
            @include m-spacing(15px,15px);
            border: solid 2px rgba(180,146,106,0.5);
            border-radius: 15px 15px 15px 0px;
            min-width: 210px;
            .inner-bg{
              @include basic-flex(column);
              @include m-spacing(10px,10px);
              @include p-spacing(10px,30px);
              background-color: rgba(180,146,106,0.5);
              border-radius: 10px 10px 10px 0px;
              h6{
                @include h6(#333);
                @include m-spacing(0px,0%);
              }
              p{
                @include p(#333);
                @include m-spacing(0px,0%);
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 765px) {
.other{
  .other-content{
    display: block;
    .taiwan-area{
      .area{
        @include basic-flex(column);
        .separate{
          width: 100%;
        }
      }
    }
  }
}
}

// @media (max-width: 960px) {
// .other{
//   .other-content{
//     .taiwan-area{
//       .area{
//         @include basic-flex(column);
//         .separate{
//           width: 100%;
//         }
//       }
//     }
//   }
// }
// }
</style>
<script>
import Taiwan from '@/components/spend/Taiwan.vue'
export default {
  name: 'Other',
  components: {
    Taiwan
  },
  data () {
    return {
      area: [
        {
          location: '北部地區',
          separate: [
            {
              name: '『 北北基 』',
              cost: '不收車馬費'
            },
            {
              name: '『 桃竹苗 』',
              cost: '不收車馬費'
            }
          ]
        },
        {
          location: '中部地區',
          separate: [
            {
              name: '『 中彰投 』',
              cost: '不收車馬費'
            },
            {
              name: '『 雲嘉 』',
              cost: 'NT$1000'
            }
          ]
        },
        {
          location: '南部地區',
          separate: [
            {
              name: '『 台南 』',
              cost: 'NT$2000'
            },
            {
              name: '『 高雄 』',
              cost: 'NT$3000'
            },
            {
              name: '『 屏東 』',
              cost: '另計'
            }
          ]
        },
        {
          location: '東部地區',
          separate: [
            {
              name: '『 宜蘭 』',
              cost: '另計'
            },
            {
              name: '『 花蓮 』',
              cost: '另計'
            },
            {
              name: '『 台東 』',
              cost: '另計'
            }
          ]
        }
      ]
    }
  }
}
</script>
