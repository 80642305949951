<template>
<div class="container license">
  <div class="max">
    <h6>逸居的專業證照</h6>
    <div class="license-block">
      <div class="license-content">
        <img src="../../assets/公安檢查人(防火避難類).jpg" alt="">
        <p>公安檢查人(防火避難類)</p>
      </div>
      <div class="license-content">
        <img src="../../assets/公安檢查人(設備類).jpg" alt="">
        <p>公安檢查人(設備安全類)</p>
      </div>
      <div class="license-content">
        <img src="../../assets/低碳建築聯盟結業證明.jpg" alt="">
        <p>建築物碳足跡評估認證結業證明</p>
      </div>
      <div class="license-content">
        <img src="../../assets/室內裝修專業技術人員登記證.png" alt="">
        <p>室內裝修專業技術人員登記證</p>
      </div>
      <div class="license-content">
        <img src="../../assets/建築師證照.jpg" alt="">
        <p>建築師證照</p>
      </div>
      <div class="license-content">
        <img src="../../assets/無障礙證書.jpg" alt="">
        <p>無障礙設施結業證書</p>
      </div>
      <div class="license-content">
        <img src="../../assets/工地主任執業證.jpg" alt="">
        <p>營造業工地主任執業證</p>
      </div>
      <div class="license-content">
        <img src="../../assets/營造業工地主任職能訓練講習結業證書.jpg" alt="">
        <p>營造業工地主任職能訓練講習結業證書</p>
      </div>
    </div>
  </div>
</div>
</template>
<style lang="scss" scoped>
.license{
  @include basic-flex(column);
  .max{
    max-width: 1500px;
    @include basic-flex(column);
    h6{
      @include h6(#333);
      @include m-spacing(10px,0px);
    }
    .license-block{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 90%;
      flex-wrap: wrap;
      .license-content{
        width: 33.33%;
        @include basic-flex(column);
        @include m-spacing(30px,0px);
        img{
          width: 220px;
          border: solid 5px rgba(180,146,106,.3);
          border-radius: 10px;
          // box-shadow: 1px 1px 2px 3px rgba(180,146,106,.3);
        }
        p{
          @include p(#333);
          @include m-spacing(20px,0px);
        }
      }
    }
  }
}
@media (max-width: 1100px) {
  .license{
    .max{
      .license-block{
        .license-content{
          width: 50%;
        }
      }
    }
  }
}
@media (max-width: 840px) {
  .license{
    .max{
      .license-block{
        .license-content{
          width: 100%;
        }
      }
    }
  }
}
</style>
