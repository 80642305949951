<template>
  <div class="container cost">
    <div class="max">
      <h6>收費標準</h6>
      <hr>
      <div class="card-block">
        <div class="card" v-for="(item,index) in cost" :key="index">
          <div class="card-img">
            <img :src="item.img" alt="">
          </div>
          <div class="card-content">
            <p class="name">{{item.name}}</p>
            <div class="price-block">
              <p class="price" v-for="(price, index) in item.price" :key="index">{{price}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.cost{
  @include basic-flex(column);
  @include p-spacing(30px, 30px);
  background-color: #f9f9f9;
  .max{
    max-width: 1500px;
    @include basic-flex(column);
    h6{
      @include h6(#333);
      @include m-spacing(10px,0px);
    }
    hr{
      width: 50px;
      border: solid 3px rgba(180,146,106);
      border-radius: 3px;
    }
    .card-block{
      display: flex;
      @include m-spacing(20px, 5%);
      width: 80%;
      // flex-direction: row;
      .card{
        @include m-spacing(10px, 2%);
        @include p-spacing(30px, 1.5%);
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 30%;
        background-color: #eee;
        border-radius: 10px;
        .card-img{
          width: 90%;
          height: 170px;
          background-color: #333;
          border-radius: 2px;
          overflow: hidden;
          img{
            width: 100%;
          }
        }
        .card-content{
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 10px;
          width: 90%;
          flex: 1;
          .name{
            text-align: left;
            font-size: 17px;
            letter-spacing: 2px;
            margin-top: 5px;
            width: 100%;
            padding-left: 5px;
          }
          .price-block{
            width: 100%;
            height: 100%;
            border-top: solid 2px #444;
            margin-top: 0px;
            padding-top: 12px;
            .price{
              margin: 5px;
              font-size: 15px;
              letter-spacing: 1px;
              color: #333;
            }
          }
        }
      }
    }
  }
}

@media (max-width:1380px) {
  .cost{
    .max{
      .card-block{
        flex-wrap: wrap;
        width: 100%;
        .card{
          width: 45%;
          @include m-spacing(30px, 2%);
        }
      }
    }
  }
}
@media (max-width:680px) {
  .cost{
    .max{
      .card-block{
        flex-wrap: wrap;
        .card{
          width: 100%;
        }
      }
    }
  }
}
</style>
<script>
export default {
  data () {
    return {
      cost: [
        {
          name: '公寓/大樓',
          img: '../images/公寓.jpg',
          price: ['30坪以下 -- NT$11,000~14,000', '31~70坪 -- NT$15,000~16,000', '71坪以上 (專案價) -- NT$200/坪', '另有團驗優惠，歡迎洽詢', '備註 : 坪數計算為實坪不含公設']
        },
        {
          name: '別墅/透天',
          img: '../images/透天.jpg',
          price: ['70坪以下 -- NT$28,000', '71-90坪 -- NT$32,000', '90坪以上 (專案價) -- NT$300/坪']
        },
        {
          name: '其他建築物',
          img: '../images/其他建築物.jpg',
          price: ['另行諮詢報價']
        }
      ]
    }
  }
}
</script>
