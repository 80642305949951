<template>
  <div class="container comments">
    <div class="max">
      <h6>顧客評價</h6>
      <q-carousel
        v-model="slide"
        swipeable
        transition-prev="slide-right"
        transition-next="slide-left"
        animated
        infinite
        control-color="accent"
        navigation
        arrows
        padding
        color="grey"
        :color-selected="ratingColors"
      >
        <q-carousel-slide :name="index" class="no-wrap flex-center" v-for="(items, index) in useable" :key="index">
          <div class="comment-area" v-for="(item, index) in items" :key="index">
            <div class="content">
              <div class="top">
                <div class="avatar">
                  <!-- <img :src="item.shotUrl" alt=""> -->
                  <p>{{item.name.slice(0, 1)}}</p>
                </div>
                <div class="people">
                  <p class="name">{{item.name}}</p>
                  <p class="date">{{item.dateTime}}</p>
                </div>
                <a class="fb" :href="item.url">
                  <svg width="384" height="384" viewBox="0 0 384 384" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M206.52 0C211.08 1.71 215.95 1.44 220.66 2.19C254.081 7.32044 285.544 21.2231 311.84 42.48C313.44 43.76 313.58 44.37 311.9 45.73C292.46 61.55 273.073 77.4267 253.74 93.36C251.96 94.83 250.74 94.36 249.16 93.36C231.263 82.7852 210.784 77.3783 190 77.74C167.228 78.0455 145.071 85.1723 126.39 98.1996C107.71 111.227 93.3658 129.556 85.2098 150.82C84.7698 151.97 84.2098 153.09 83.7198 154.22C76.8598 148.68 70.0031 143.14 63.1498 137.6C49.2698 126.333 35.3965 115.053 21.5298 103.76C21.7768 103.061 22.064 102.376 22.3898 101.71C53.2698 46.3233 99.9365 13.1533 162.39 2.2C167.1 1.38 171.98 1.68 176.56 0L206.52 0Z" fill="#F04336"/>
                    <path d="M21.53 103.76C35.3967 115.04 49.27 126.32 63.15 137.6C69.9967 143.16 76.8533 148.7 83.72 154.22C80.48 165.33 78.05 176.56 77.72 188.22C77.4301 203.031 79.8255 217.773 84.79 231.73L23 282.22C13 264.47 6.78 245.42 3 225.48C1.8 219.18 1.75 212.72 0 206.48V176.59C0.7 176.28 0.68 175.59 0.73 175.03C1.26843 167.849 2.27052 160.711 3.73 153.66C7.38 136.21 12.86 119.42 21.53 103.76Z" fill="#FABA01"/>
                    <path d="M249.11 290C265.75 279.79 279.44 266.61 289.44 249.74C292.233 244.883 294.757 239.875 297 234.74C298.26 231.97 297.44 231.42 294.65 231.43C273.83 231.51 253 231.48 232.18 231.48C221.18 231.48 210.18 231.41 199.26 231.48C196.73 231.48 195.81 230.86 195.82 228.17C195.907 205.103 195.907 182.033 195.82 158.96C195.82 156.26 196.72 155.66 199.25 155.67C258.363 155.73 317.47 155.73 376.57 155.67C379.1 155.67 379.89 156.46 380.29 158.96C389.483 217.333 375.343 269.333 337.87 314.96C330.119 324.574 321.216 333.199 311.36 340.64C309.8 339.4 308.22 338.19 306.68 336.93L249.11 290Z" fill="#518EF7"/>
                    <path d="M249.11 290L306.68 337C308.22 338.26 309.8 339.47 311.36 340.71C298.68 351.56 284.44 359.9 269.25 366.64C238.69 380.23 206.6 385.86 173.41 382.16C106.62 374.71 56.6202 341.23 23.2002 282.95C23.1012 282.721 23.0242 282.483 22.9702 282.24L84.8102 231.74C90.8102 246.53 99.0402 259.89 110.29 271.32C127.695 289.054 150.423 300.608 175.007 304.219C199.591 307.83 224.681 303.3 246.45 291.32C247.32 290.84 248.22 290.42 249.11 290Z" fill="#28B346"/>
                  </svg>
                  <!-- <img src="../../assets/fb.png" alt=""> -->
                </a>
              </div>
              <q-rating
                v-model="item.star"
                size="3.5em"
                color="grey-2"
                :color-selected="ratingColors"
                readonly
              />
              <hr>
              <p class="comment">{{item.content}}</p>
            </div>
          </div>
        </q-carousel-slide>
      </q-carousel>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.comments{
  background-color: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  .q-rating{
    :deep() i{
      font-size: 20px;
      text-shadow: none;
    }
  }
  .max{
    width: 100%;
    max-width: 1500px;
    h6{
      @include h6(#333);
      margin: 0px 0px 20px 0px;
      text-align: center;
    }
    .q-carousel{
      background-color: transparent !important;
      height: auto !important;
      i{
        color: #B4926A;
      }
      :deep() .q-carousel__navigation{
        display: none;
      }
      :deep() .q-carousel__slide{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 20px;
        .comment-area{
          background-color: #f8f8f8;
          border-radius: 10px;
          padding: 20px;
          width: 30%;
          height: 100%;
          .content{
            .top{
              display: flex;
              align-items: center;
              padding: 5px 10px 5px 0px;
              .avatar{
                width: 40px;
                min-width: 40px;
                height: 40px;
                border-radius: 100%;
                position: relative;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #B4926A;
                p{
                  margin: 0;
                  color: #fff;
                }
                img{
                  width: 100%;
                  height: 100%;
                }
              }
              .people{
                flex: 1;
                margin: 0px 10px;
                p{
                  margin: 0;
                  font-size: 18px;
                }
                // .name{
                //   margin-bottom: 5px;
                // }
                .date{
                  color: #acacac;
                  font-size: 14px;
                  transform: skew(-5deg);
                }
              }
              .fb{
                border-radius: 100%;
                display: flex;
                svg{
                  width: 40px;
                  height: 40px;
                }
              }
            }
            hr{
              border: solid 1px #ecedef;
              margin-top: 12px;
            }
            .comment{
              padding: 5px 10px;
              letter-spacing: 1px;
              font-weight: 500;
              color: #202124;
            }
          }
        }
      }
      :deep() .q-carousel__arrow{
        .q-btn{
          border: solid 3px #B4926A;
          i{
            color: #B4926A;
          }
        }
      }
    }
  }
}

@media (max-width:1299px) {
  .comments{
    .max{
      .q-carousel{
        padding: 0px 40px;
        :deep() .q-carousel__slide{
          margin: 0 !important;
          padding: 0 !important;
          .comment-area{
            width: 90%;
            max-width: 300px;
            // min-width: 300px;
          }
        }
        :deep() .q-carousel__prev-arrow{
          left: 0px;
        }
        :deep() .q-carousel__next-arrow{
          right: 0px;
        }
        :deep() .q-carousel__navigation{
          display: none;
        }
      }
    }
  }
}
@media (max-width:480px) {
  .container.comments{
    @include p-spacing(40px,5%);
    .max{
      .q-carousel{
        :deep() .q-carousel__slide{
          .comment-area{
            width: 100%;
            max-width: 260px;
            padding: 20px 10px;
            .content{
              .top{

                padding: 5px;
              }
            }
          }
        }
      }
    }
  }
}
</style>

<script>
import { ref } from 'vue'
import { getComments } from '@/api/api'
import dayjs from 'dayjs'

export default {
  name: 'Comments',
  components: {
  },

  data () {
    return {
      slide: ref(0),
      comments: [],
      useable: [],
      ratingColors: ['warning']
    }
  },
  methods: {
    getUseable (num) {
      this.comments.forEach((item, i) => {
        const index = Math.floor(i / num)
        if (!this.useable[index]) {
          this.useable.push([])
        }
        this.useable[index].push(item)
      })
    },
    apiStart () {
      return new Promise((resolve, reject) => {
        getComments().then((result) => {
          console.log('[ getComments ] result : ', result)
          result.list.forEach((item) => {
            // item.dateTime = item.dateTime.trim().split(/\s+/)[0]
            const newD = item.dateTime.replace('at ', '')
            console.log(newD)
            item.dateTime = dayjs(newD).format('YYYY/MM/DD')
            item.content = item.content.replace(' See more', '')
          })
          this.comments = result.list
          resolve(result)
        }).catch((err) => {
          console.log('[ getComments ] err : ', err.response)
          reject(err)
        })
      })
    }
  },
  mounted () {
    this.apiStart().then(() => {
      return this.$nextTick()
    }).then(() => {
      const windowWidth = window.innerWidth
      if (windowWidth < 1130) {
        this.getUseable(1)
      } else {
        this.getUseable(3)
      }
    })
  }
}
</script>
