import { render } from "./About.vue?vue&type=template&id=75f11b2e&scoped=true"
import script from "./About.vue?vue&type=script&lang=js"
export * from "./About.vue?vue&type=script&lang=js"

import "./About.vue?vue&type=style&index=0&id=75f11b2e&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-75f11b2e"

export default script
import QPage from 'quasar/src/components/page/QPage.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QPage});
