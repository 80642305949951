<template>
  <q-page>
    <NotFound v-if="!hasFound" />
    <div class="found" v-if="hasFound">
      <Banner />
      <div class="container case">
        <div class="max">
          <h6>驗屋案例</h6>
          <hr />
          <div class="intro">
            <div class="left">
              <img :src="data.cover" alt="">
            </div>
            <div class="right">
              <div class="basic-intro">
                <div v-html="data.text"></div>
              </div>
              <!-- <div class="focus-intro">
                <h6>驗屋檢測重點：</h6>
                <p>1.天然氣體管線滲漏檢測<br>2.浴室乾濕區漏水檢測<br>3.水管管壓漏水洩壓測試<br>4.牆體混凝土含水率測試<br>5.熱顯像儀-水氣分佈測試<br>6.地面牆面蜂巢空心空鼓檢測<br>7.管道內視鏡水管破口檢測攝影<br>8.磁磚空心空鼓檢</p>
              </div>
              <div class="seo">
                <p>台北市驗屋/新北市驗屋/新北驗屋/新竹驗屋/中和驗屋/北屯區驗屋/高雄驗屋/台中市驗屋/彰化市驗屋/台南市驗屋/永康驗屋/南投市驗屋/淡水驗屋/建商交屋/新屋驗收/交屋注意事項/交屋/全台驗屋/房屋檢驗/新成屋驗屋/聯開宅驗屋<br>驗屋範圍:新屋交屋/全台驗屋/房屋檢驗/新成屋驗屋/聯開宅驗屋/居家安全/ 買的放心/驗屋師/建商交屋/新屋點交/台中驗屋/新屋驗收/驗屋公司費用/驗屋推薦/台南驗屋公司推薦</p>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </q-page>
  <Loading v-if="loading" />
</template>
<style lang="scss">
.case {
  @include basic-flex(column);
  .max{
    @include basic-flex(column);
    max-width: 1200px;
    h6 {
      @include h6(#333);
    }
    hr {
      width: 50px;
      border: solid 3px rgba(180, 146, 106);
      border-radius: 3px;
    }
    .intro {
      // @include p-spacing(10px, 5%);
      @include m-spacing(30px, 0%);
      @include basic-flex(row);
      align-items: stretch;
      width: 100%;
      .left{
        flex: 1;
        @include p-spacing(1%, 1%);
        background-color: #f4f4f4;
        border-radius: 5px;
        img{
          width: 100%;
        }
      }
      .right{
        flex: 1;
        @include p-spacing(1%, 1%);
        div{
          margin: 0px 20px 20px 20px;
          div{
            h1{
              font-size: 18px;
              margin: 0px 0px 20px 0px;
              padding: 0;
              line-height: 30px;
            }
            h2{
              font-size: 18px;
              margin: 0px 0px 20px 0px;
              padding: 0;
              line-height: 30px;
            }
            h3{
              font-size: 18px;
              margin: 0px 0px 20px 0px;
              padding: 0;
              line-height: 30px;
            }
          }
          h6{
            @include h6(#333);
            @include m-spacing(0px, 0%);
            text-align: left;
          }
          p{
            @include p(#333);
          }
        }
      }
    }
  }
}
@media (max-width: 1420px){
  .case{
    .max{
      .intro {
        @include basic-flex(column);
        .right{
          margin: 20px 0px 20px 0px;
          div{
            margin: 0px 0px 20px 0px;
          }
        }
      }
    }
  }
}
</style>
<script>
import Banner from '@/components/case/Banner.vue'
import NotFound from '@/components/case/404case.vue'
import Loading from '@/components/Loading.vue'
import { getCaseByUrl, getCases } from '@/api/api'
import { useMeta } from 'vue-meta'

export default {
  name: 'Case',
  components: {
    Banner,
    NotFound,
    Loading
  },
  data () {
    return {
      loading: true,
      hasFound: true,
      data: {},
      dynamicMeta: '',
      meta: useMeta({
        title: ''
      })
    }
  },
  computed: {
    checkMeta () {
      let info = ''
      if (this.hasFound === true) {
        info = this.dynamicMeta
      } else {
        info = '404'
      }
      return info
    }
    // getData () {
    //   const data = this.$store.state.cases
    //   const id = this.$route.params.caseId
    //   const final = ['']
    //   data.forEach(e => {
    //     e.case.forEach(k => {
    //       if (k.link === id) {
    //         final[0] = k
    //         // final.push(k)
    //       }
    //     })
    //   })
    //   return final[0]
    // },
    // hasFound () {
    //   const data = this.$store.state.cases
    //   const id = this.$route.params.caseId
    //   const found = data.some(e => {
    //     const equal = e.case.some(k => {
    //       return k.link === id
    //     })
    //     return equal
    //   })
    //   return found
    // }
  },
  watch: {
    dynamicMeta: function () {
      this.meta.meta.title = this.checkMeta
    }
  },
  methods: {
    getAllCases () {
      getCases().then((result) => {
        const id = this.$route.params.caseId
        console.log('[ getCases ] result : ', result)
        const found = result.list.some(e => {
          return e.url === id && e.setting.isShow === true
        })
        if (found === true) {
          this.hasFound = true
        } else {
          this.loading = false
          this.hasFound = false
        }
      }).catch((err) => {
        console.log('[ getCases ] err : ', err.response)
      })
    },
    getCase () {
      return new Promise((resolve, reject) => {
        const id = this.$route.params.caseId
        getCaseByUrl(id).then((e) => {
          console.log('[ getCaseByUrl ] post : ', e)
          this.data = e
          this.dynamicMeta = e.title
          this.loading = false
          resolve(e)
        }).catch((err) => {
          console.log('[ getCaseByUrl ] err : ', err)
          reject(err)
        })
      })
    }
  },
  created () {
    this.getAllCases()
    this.getCase()
    useMeta({
      title: this.meta
    })
  }
}
</script>
