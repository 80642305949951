import { render } from "./Spend.vue?vue&type=template&id=5c4fa516&scoped=true"
import script from "./Spend.vue?vue&type=script&lang=js"
export * from "./Spend.vue?vue&type=script&lang=js"
script.render = render
script.__scopeId = "data-v-5c4fa516"

export default script
import QPage from 'quasar/src/components/page/QPage.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QPage});
