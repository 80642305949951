<template>
<div class="container service">
  <div class="desktop">
    <h6>服務項目</h6>
    <p class="second-t">『 層層把關 、層層安心 』</p>
    <div class="service-block">
      <div class="left">
        <p>◉ 不動產合約檢視
          <br>◉ 室內環境檢測
          <br>◉ 屋況檢查
          <br>◉ 室內用電安全檢測
          <br>◉ 弱電功能檢查
          <br>◉ 修繕、裝修工程諮詢
        </p>
      </div>
      <div class="center">
        <div class="bubble"></div>
        <a href="/we-do-all-of-this">
          <div class="service-ball">
            <p>服務項目</p>
          </div>
        </a>
      </div>
      <div class="right">
        <p>◉ 建材、合約內容覆核
          <br>◉ 建築法規內容檢視
          <br>◉ 建築物結構安全檢視
          <br>(非破壞性)
          <br>◉ 施工品質檢查
          <br>◉ 漏水位置檢測
        </p>
      </div>
    </div>
    <a href="https://page.line.me/001qvmvm?openQrModal=true" class="a-btn">立即線上預約</a>
  </div>
  <div class="mobile">
    <h6>服務項目</h6>
    <p class="second-t">『 層層把關 、層層安心 』</p>
    <div class="mobile-service">
      <p>◉ 不動產合約檢視
        <br>◉ 室內環境檢測
        <br>◉ 屋況檢查
        <br>◉ 室內用電安全檢測
        <br>◉ 弱電功能檢查
        <br>◉ 修繕、裝修工程諮詢
      </p>
      <p>◉ 建材、合約內容覆核
        <br>◉ 建築法規內容檢視
        <br>◉ 建築物結構安全檢視 (非破壞性)
        <br>◉ 施工品質檢查
        <br>◉ 漏水位置檢測
      </p>
    </div>
  </div>
</div>
</template>
<style lang="scss" scoped>
.desktop{
  @include basic-flex(column);
  h6{
    @include h6(#333);
  }
  .second-t{
    @include m-spacing(10px,0px);
    @include p(rgba(180,146,106));
  }
  .service-block{
    @include basic-flex(row);
    @include m-spacing(20px,0px);
    align-items: stretch;
    .left,.center,.right{
      @include basic-flex(column);
    }
    .left,.right{
      width: 40%;
      p{
        @include p(#333);
        @include p-spacing(30px,30px);
        border: solid 5px #eee;
        background-color: #f4f4f4;
        // background-color: rgba(180,146,106,.4);
        width: 88%;
        border-radius: 8% 15%;
        height: 100%;
        transition-duration: 0.5s;
        &:hover{
          border-radius: 15% 8%;
        }
      }
    }
    .center{
      position: relative;
      width: 350px;
      @include m-spacing(30px,30px);
      &::before{
        content: '';
        background-image: url('../../assets/arrow.png');
        position: absolute;
        width: 50px;
        height: 40px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        left: 0px;
        top: 50%;
        transform: translate(-50%,-50%) rotate(180deg);
        opacity: 0.2;
      }
      &::after{
        content: '';
        background-image: url('../../assets/arrow.png');
        position: absolute;
        width: 50px;
        height: 40px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        right: 0px;
        top: 50%;
        transform: translate(50%,-50%);
        opacity: 0.2;
      }
      .bubble{
        position: absolute;
        width: 180px;
        height: 180px;
        background-color: rgba(180,146,106,.4);
        border-radius: 100%;
        animation: bubble 1.5s infinite ease-out;
      }
      a{
        text-decoration: none;
        z-index: 9;
        .service-ball{
          @include basic-flex(column);
          width: 150px;
          height: 150px;
          background-color: #B4926A;
          border-radius: 100%;
          p{
            @include h6(#fff);
          }
        }
      }
    }
  }
  .a-btn{
    @include a-btn(#333,none,#fff);
    @include m-spacing(25px, 0px)
  }
}
.mobile{
  display: none;
  // background-color: rgba(180,146,106,.4);
  h6{
    @include h6(#333);
  }
  .second-t{
    @include m-spacing(10px,0px);
    @include p(rgba(180,146,106));
  }
  .mobile-service{
    // border: solid 4px rgba(180,146,106,.4);
    background-color: rgba(180,146,106,.2);
    width: 100%;
    margin-top: 10px;
    border-radius: 10px;
    @include basic-flex(row);
    @include p-spacing(30px,20px);
    align-items: flex-start;
    p{
      @include p(#333);
      margin: 0px 5%;
      width: 160px;
      line-height: 30px;
    }
  }
}
@media (max-width: 1040px){
.desktop{
  display: none;
}
.mobile{
  @include basic-flex(column);
}
}
@media (max-width: 540px){
.mobile{
  .mobile-service{
    @include basic-flex(column);
  }
}
}
</style>
