<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content ? `逸居專業驗屋WINSPECTION | ${content} `  : `逸居專業驗屋WINSPECTION` }}</template>
  </metainfo>
  <q-layout id="root">
    <Header />
    <!-- <transition name="page" mode="out-in"> -->
    <router-view :key="$route.path"></router-view>
    <!-- </transition> -->
    <Footer />
  </q-layout>
</template>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+HK:wght@300;400;500&display=swap');
body{
  font-family: 'Noto Sans TC', sans-serif !important;
  font-weight: 700;
}
</style>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import { useMeta } from 'vue-meta'
// import Home from './views/Home.vue'

export default {
  name: 'LayoutDefault',

  components: {
    Header,
    Footer
  },

  data () {
    return {
    }
  },

  methods: {
  },
  created () {
    useMeta({
      title: ''
      // htmlAttrs: { lang: 'en', amp: true }
    })
  }
  // watch: {
  //   $route: function (to, from) {
  //     const el = document.scrollingElement || document.documentElement
  //     document.body.scrollTop = 0
  //     el.scrollTop = 0
  //   }
  // }
}
</script>
