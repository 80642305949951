<template>
  <teleport to="head">
    <title>關於逸居</title>
  </teleport>
  <q-page>
    <Banner />
    <Winspection />
    <License />
    <Related />
  </q-page>
</template>
<style lang="scss" scoped>
.container{
  @include p-spacing(40px, 10%);
}
@media (max-width:680px) {
  .container{
    @include p-spacing(30px, 5%);
  }
}
</style>
<script>
import Banner from '@/components/about/Banner.vue'
import Winspection from '@/components/about/Winspection.vue'
import License from '@/components/about/License.vue'
import Related from '@/components/about/Related.vue'
import { useMeta } from 'vue-meta'

export default {
  name: 'About',
  // metaInfo: {
  //   title: '關於逸居'
  // },
  components: {
    Banner,
    Winspection,
    License,
    Related
  },
  data () {
    return {}
  },
  methods: {},
  created () {
    useMeta({ title: '關於我們' })
  }
}
</script>
