<template>
  <NotFound v-if="!hasFound" />
  <q-page v-if="hasFound">
    <div class="banner-bg"></div>
    <div class="container post-container">
      <div class="max">
        <div class="post">
          <div class="post-account">
            <img src="../assets/header-logo.svg" alt="" class="account-logo">
            <p>Winspection G</p>
            <img src="../assets/badge.png" alt="">
            <p>·{{post.publishAt}}</p>
          </div>
          <h6>{{post.title}}</h6>
          <p class="date">發布日期：{{post.publishAt}}</p>
          <div v-html="post.text" ref='content'></div>
          <div class="back">
            <router-link to="/winspection-blog">⇨ 返回文章列表</router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="popup" v-if="showPopup">
      <img :src="popupImg" alt="">
      <div class="cancel" @click="cancelPopup()">X</div>
    </div>
  </q-page>
  <Loading v-if="loading" />
</template>
<style lang="scss">
.banner-bg{
  background-color: #b4926a;
  height: 66px;
}
.post-container{
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  img{
    max-width: 100%;
  }
  .max{
    @include basic-flex(column);
    width: 80%;
    max-width: 1200px;
    .post{
      width: 100%;
      .post-account{
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .account-logo{
          margin-right: 10px;
        }
        img{
          width: 15px;
        }
        p{
          margin: 0;
          letter-spacing: 0px;
          font-size: 10px;
          color: #999;
          padding: 0px 5px;
        }
      }
      .flex-img-3{
        display: flex;
        flex-wrap: wrap;
        margin: 20px 0px;
        border: solid 2px #eee;
        .img-item{
          width: 31%;
          margin: 1%;
          height: 180px;
          overflow: hidden;
          img{
            width: 100%;
          }
        }
      }
      .flex-img-2{
        display: flex;
        flex-wrap: wrap;
        margin: 20px 0px;
        border: solid 2px #eee;
        .img-item{
          width: 48%;
          margin: 1%;
          height: 450px;
          overflow: hidden;
          img{
            width: 100%;
          }
        }
      }
      .img-block{
        margin: 10px 0px;
        img{
          width: 100%;
          margin: 10px 0px;
        }
      }
      .hashtag-block{
        margin: 20px 0px;
      }
      a{
        color: #b4926a;
        text-decoration: none;
      }
      h6{
        padding: 0px;
        margin: 20px 0px 10px 0px;
        letter-spacing: 1px;
      }
      .date{
        font-size: 10px;
        color: #999;
        letter-spacing: 1px;
        border-bottom: dashed 2px #ddd;
        padding-bottom: 20px;
        margin-bottom: 20px;
      }
      p{
        letter-spacing: 2px;
        font-weight: 500;
        font-size: 15px;
        margin: 0%;
        line-height: 30px;
      }
      a{
        letter-spacing: 1px;
      }
      ul{
        list-style: none;
        padding: 0;
        li{
          line-height: 30px;
        }
      }
      .statement{
        color: red;
        margin: 10px 0px;
      }
      .back{
        margin: 50px 0px 30px 0px;
        border: solid 2px #b4926a;
        display: inline-block;
        padding: 10px 20px;
        a{
          letter-spacing: 3px;
        }
      }
    }
  }
}
.popup{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 9999;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    max-width: 80%;
    max-height: 80%;
  }
  .cancel{
    border: solid 2px #000;
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 7px 15px;
    border-radius: 5px;
    transition-duration: 0.5s;
    cursor: pointer;
    &:hover{
      background-color: #000;
      color: #fff;
    }
  }
}
@media (max-width: 780px) {
.post-container{
  .max{
    width: 100%;
    .post{
      width: 90%;
    }
  }
}
}
</style>
<script>
import NotFound from '@/components/blog/404post.vue'
import Loading from '@/components/Loading.vue'
import { getPostByUrl, getPosts } from '@/api/api'
import { useMeta } from 'vue-meta'
import dayjs from 'dayjs'

export default {
  name: 'Post',
  components: {
    NotFound,
    Loading
  },
  data () {
    return {
      popupImg: '',
      showPopup: false,
      post: '',
      hasFound: true,
      loading: true,
      dynamicMeta: '',
      meta: useMeta({
        title: ''
      })
    }
  },
  mounted () {
    this.getPost().then(() => {
      return this.$nextTick()
    }).then(() => {
      this.checkNotFoundInit()
    })
  },
  beforeUnmount () {
    this.checkNotFoundRemove()
  },
  computed: {
    checkMeta () {
      let info = ''
      if (this.hasFound === true) {
        info = this.dynamicMeta.title
      } else {
        info = '404'
      }
      return info
    }
  },
  watch: {
    dynamicMeta: function () {
      this.meta.meta.title = this.checkMeta
    }
  },
  methods: {
    getAllPost () {
      getPosts().then((result) => {
        const id = this.$route.params.postId
        console.log('[ getPosts ] result : ', result)
        const found = result.posts.some(e => {
          return e.url === id && e.setting.isShow === true
        })
        if (found === true) {
          this.hasFound = true
        } else {
          this.loading = false
          this.hasFound = false
        }
        const metaResult = result.posts.find(e => e.url === id)
        this.dynamicMeta = metaResult
      }).catch((err) => {
        console.log('[ getPosts ] err : ', err.response)
      })
    },
    getPost () {
      return new Promise((resolve, reject) => {
        const id = this.$route.params.postId
        getPostByUrl(id).then((post) => {
          console.log('[ getPostByUrl ] post : ', post)
          const dateInfo = dayjs.unix(post.publishAt)
          post.publishAt = dayjs(dateInfo).format('YYYY年MM月DD日')
          this.post = post
          this.loading = false
          resolve(post)
        }).catch((err) => {
          console.log('[ getPostByUrl ] err : ', err)
          reject(err)
        })
      })
    },
    checkNotFoundInit () {
      if (this.hasFound === true) {
        this.initListener()
      }
    },
    checkNotFoundRemove () {
      if (this.hasFound === true) {
        this.removeListener()
      }
    },
    cancelPopup () {
      this.showPopup = false
    },
    initListener () {
      const contentDom = this.$refs?.content
      if (!contentDom) {
        console.log('[ initListener ] 無法取得 content DOM')
        return false
      }
      const imgCollection = contentDom.getElementsByTagName('img')
      const imgs = imgCollection
      imgs.forEach((img) => img.addEventListener('click', this.handleImgClick))

      const cancelWatcher = this.$watch('content', async () => {
        cancelWatcher()
        imgs.forEach((img) => {
          img.removeEventListener('click', this.handleImgClick)
        })
        await this.$nextTick()
        this.initListener()
      })
    },
    removeListener () {
      const contentDom = this.$refs?.content
      const imgCollection = contentDom.getElementsByTagName('img')
      const imgs = [...imgCollection]
      imgs.forEach((img) => {
        img.removeEventListener('click', this.handleImgClick)
      })
    },
    handleImgClick (e) {
      this.popupImg = e?.target?.src ?? null
      this.showPopup = true
    }
  },
  created () {
    this.getAllPost()
    useMeta({
      title: this.meta
    })
  }
}
</script>
