import { render } from "./Items.vue?vue&type=template&id=12456161&scoped=true"
import script from "./Items.vue?vue&type=script&lang=js"
export * from "./Items.vue?vue&type=script&lang=js"

import "./Items.vue?vue&type=style&index=0&id=12456161&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-12456161"

export default script
import QPage from 'quasar/src/components/page/QPage.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QPage});
