<template>
<div class="banner">
  <div class="banner-center">
    <h1>逸居前端科技檢驗</h1>
    <!-- <h6>驗屋就交給逸居</h6> -->
    <NumberEffect></NumberEffect>
    <div class="button-block">
      <router-link to="/we-do-all-of-this">
        <q-btn color="primary" label="服務項目"/>
      </router-link>
      <a href="https://page.line.me/001qvmvm?openQrModal=true">
        <q-btn color="primary" label="立即聯絡"/>
      </a>
    </div>
  </div>
</div>
</template>

<style lang="scss" scoped>
.banner{
  height: 100vh;
  width: 100%;
  background-image: url('../../assets/home-banner.jpeg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  &::before{
    content: "";
    position: absolute;
    background-color: rgba(0,0,0,0.5);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .banner-center{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    h1{
      @include h1(#fff);
    }
    h6{
      @include h6(#fff);
      @include m-spacing(30px, 0px);
    }
    .button-block{
      @include basic-flex(row);
      @include m-spacing(30px, 0px);
      a{
        text-decoration: none;
        .q-btn{
          margin: 0px 10px;
          padding: 0px 40px;
          letter-spacing: 1px;
          font-size: 16px;
        }
      }
    }
  }
}
@media (max-width:680px) {
.banner{
  .banner-center{
    width: 100%;
    h1{
      font-size: 32px;
      @include m-spacing(10px,10px);
    }
    h6{
      font-size: 16px;
      @include m-spacing(10px,10px);
    }
    .button-block{
      width: 100%;
      .q-btn{
        padding: 0px 30px !important;
        font-size: 16px;
        .q-btn__content{
          .block{
            font-size: 14px !important;
          }
        }
      }
    }
  }
}
}
</style>
<script>
import NumberEffect from '../../components/effect/number-grow.vue'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)
export default {
  name: 'Banner',
  components: {
    NumberEffect
  },
  mounted: function () {
    this.show()
    setTimeout(function () {
      window.scrollTo(0, 0)
    }, 100)
  },
  methods: {
    show () {
      gsap.timeline({
        scrollTrigger: {
          trigger: 'h1',
          start: 'top bottom'
        }
      })
        .from('.button-block', {
          y: 30,
          opacity: 0,
          duration: 1.5,
          ease: 'easeOut'
        })
      // gsap.timeline({
      //   scrollTrigger: {
      //     trigger: 'h1',
      //     start: 'top bottom'
      //   }
      // })
      //   .from('.button-block', {
      //     y: 30,
      //     opacity: 0,
      //     duration: 1.5,
      //     ease: 'easeOut',
      //     onComplete: () => {
      //       console.log('eventCallback onComplete')
      //     }
      //   })
    }
  }
}
</script>
