<template>
<div class="banner-bg"></div>
<div class="container booking">
  <div class="max">
    <h6>預約服務</h6>
    <hr>
    <router-view></router-view>
  </div>
</div>
</template>
<style lang="scss" scoped>
.booking{
  @include basic-flex(column);
  margin-bottom: 40px;
  padding: 40px 5% !important;
  .max{
    width: 100%;
    @include basic-flex(column);
    h6{
      @include h6(#333);
      @include m-spacing(10px,0px);
    }
    hr{
      width: 50px;
      border: solid 3px rgba(180,146,106);
      border-radius: 3px;
    }
  }
}
</style>
<script>
import { useMeta } from 'vue-meta'
export default {
  name: 'Booking',
  components: {
  },
  data () {
    return {
      message: ''
    }
  },
  created () {
    useMeta({ title: '預約服務' })
  },
  mounted () {
  },
  methods: {
  }
}
</script>
