<template>
  <div class="footer-area">
    <q-footer class="footer">
      <div class="footer-block">
        <h6>逸居專業驗屋｜驗屋推薦</h6>
        <p class="second-title">WINSPECTION</p>
        <hr>
        <div class="bottom">
          <div class="left">
            <p class="highlight">HOME INSPECTION SERVICES</p>
            <p>401 台中市東區台中路359號1樓<br>
              Contact | 04 - 2281 - 6090 / Service Time | 9:00 - 18:00
            </p>
          </div>
          <div class="right">
            <div class="social">
              <a href="https://www.facebook.com/winspection">
                <img src="../assets/footer-fb.png" alt="">
              </a>
              <a href="https://page.line.me/001qvmvm?openQrModal=true">
                <img src="../assets/footer-line.png" alt="">
              </a>
            </div>
            <p>© 2021 逸居科技檢驗股份有限公司</p>
          </div>
        </div>
      </div>
    </q-footer>
    <a class="bg-bottom" href="https://bgmotion.com.tw/home">
      <div class="inner">
        <div>© 2021 背景模式 BGMotion Web Design, all rights reserved</div>
      </div>
    </a>
  </div>
</template>
<style lang="scss" scoped>
  .footer-area{
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .q-footer{
    position: relative;
    width: 100%;
    background-color: #222;
    padding: 60px 10% 40px 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 99;

    .footer-block{
      width: 100%;
      max-width: 1500px;
      h6{
        margin: 0;
        font-size: 20px;
        letter-spacing: 1px;
      }
      .second-title{
        font-size: 16px;
        color: #d4d4d4;
        margin: 5px 0px;
        letter-spacing: 1px;
      }
      hr{
        margin-top: 20px;
      }
      .bottom{
        @include basic-flex(row);
        align-items: stretch;
        margin-top: 20px;
        .left{
          flex: 1;
          .highlight{
            color:rgba(180, 146, 106);
          }
          p{
            line-height: 22px;
          }
        }
        .right{
          @include basic-flex(column);
          align-items: flex-end;
          flex: 1;
          justify-content: space-between;
          .social{
            img{
              width: 30px;
              margin-left: 15px;
            }
          }
        }
      }
      .small-title{
        color:rgba(180, 146, 106);
        margin: 20px 0px 0px 0px;
        letter-spacing: 1px;
      }
    }
  }
  .bg-bottom{
    background-color: rgba(180, 146, 106, 1);
    padding: 10px 10% 10px 10%;
    width: 100%;
    // text-align: center;
    color: #fff;
    text-decoration: none;
    display: flex;
    justify-content: center;
    .inner{
      width: 100%;
      max-width: 1500px;
    }
  }

  @media (max-width: 920px) {
    .q-footer{
      .footer-block{
        // width: 90%;
        .bottom{
          @include basic-flex(column);
          .left{
            width: 100%;
          }
          .right{
            width: 100%;
            align-items: flex-start;
            .social{
              margin-bottom: 10px;
              img{
                margin-left: 0px;
                margin-right: 15px;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width:680px) {
    .q-footer{
      @include p-spacing(30px, 10%);
    }
  }
</style>

<script>
export default {
  name: 'Footer',
  data () {
    return {
    }
  }
}
</script>
