<template>
  <q-page>
    <Banner />
    <Intro />
    <Comments />
    <Service />
    <Cost />
    <Process />
  </q-page>
</template>

<style lang="scss" scoped>
.container{
  @include p-spacing(40px, 10%);
}

@media (max-width:680px) {
  .container{
    @include p-spacing(30px, 5%);
  }
}
</style>

<script>
// @ is an alias to /src
import Banner from '@/components/home/Banner.vue'
import Intro from '@/components/home/Intro.vue'
import Comments from '@/components/home/Comments.vue'
import Service from '@/components/home/Service.vue'
import Cost from '@/components/spend/Cost.vue'
import Process from '@/components/home/Process.vue'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { useMeta } from 'vue-meta'
gsap.registerPlugin(ScrollTrigger)

export default {
  name: 'Home',
  components: {
    Banner,
    Intro,
    Comments,
    Service,
    Cost,
    Process
  },
  data () {
    return {}
  },
  methods: {},
  created () {
    useMeta({ title: '首頁' })
  }
}
</script>
