<template>
  <q-page>
    <Banner />
    <Cost />
    <Other />
  </q-page>
</template>
<style lang="scss" scoped>
</style>
<script>
import Banner from '@/components/spend/Banner.vue'
import Cost from '@/components/spend/Cost.vue'
import Other from '@/components/spend/Other.vue'
import { useMeta } from 'vue-meta'
export default {
  name: 'Spend',
  components: {
    Banner,
    Cost,
    Other
  },
  data () {
    return {
    }
  },
  methods: {},
  created () {
    useMeta({ title: '費用標準' })
  }
}
</script>
