<template>
  <div class="notfound">
    <div class="banner-bg"></div>
    <div class="notfound-container">
      <h2 class="title">404</h2>
      <!-- <img src="../../assets/404.svg" alt=""> -->
      <p class="second-title">Page Not Found !</p>
      <p class="ch-p">在這裡找不到符合條件的相關內容，建議您回到《逸居首頁》或是前往《列表導覽》。</p>
      <div class="link-block">
        <div class="home">
          <!-- <p>返回逸居專業驗屋首頁</p> -->
          <router-link to="/">首頁 Home</router-link>
        </div>
        <div class="list">
          <!-- <p>前往逸居專業驗屋服務案例</p> -->
          <router-link to="/service-area">服務案例列表</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.notfound {
  background-color: #f4f4f4;
  min-height: calc(100vh - 66px);
  display: flex;
  flex-direction: column;
  position: relative;

  .banner-bg {
    background-color: #b4926a;
    height: 66px;
    z-index: 2;
  }

  .notfound-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    flex: 1;

    .title {
      display: inline-block;
      margin: 40px;
      letter-spacing: 2px;
      font-weight: 900;
      font-size: 100px;
      z-index: 9;
    }

    .second-title {
      font-weight: 900;
      font-size: 20px;
      color: #b4926a;
      z-index: 9;
    }

    .ch-p {
      letter-spacing: 1px;
      z-index: 9;
    }

    .link-block {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      z-index: 9;

      .home,
      .list {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      a {
        text-decoration: none;
        border: solid 3px #000000;
        padding: 5px 15px;
        color: #000;
        border-radius: 5px;
        margin: 0px 10px 20px 10px;
        letter-spacing: 1px;
        width: 150px;
        text-align: center;
      }
    }
  }
}
</style>
<script>
import { useMeta } from 'vue-meta'
export default {
  name: '404case',
  mounted: function () {
  },
  methods: {
  },
  created () {
    useMeta({
      title: '404'
    })
    var meta = document.createElement('meta')
    meta.name = 'prerender-status-code'
    meta.content = '404'
    document.getElementsByTagName('head')[0].appendChild(meta)
  },
  unmounted () {
    document.querySelector('meta[name=prerender-status-code]').remove()
  }
}
</script>
