<template>
  <div class="container common">
    <div class="max">
      <h6>常見缺失及檢測項目說明</h6>
      <hr>
      <div class="items">
        <div class="item" v-for="(item,index) in items" :key="index">
          <div class="top">
            <img :src="item.img" alt="">
          </div>
          <div class="bottom">
            <h6>{{item.name}}</h6>
            <div class="line"></div>
            <Expansion :parent-msg="item.content" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.common{
  @include basic-flex(column);
  .max{
    max-width: 1500px;
    @include basic-flex(column);
    h6{
      @include h6(#333);
      @include m-spacing(10px,0px);
    }
    hr{
      width: 50px;
      border: solid 3px rgba(180,146,106);
      border-radius: 3px;
    }
    .items{
      @include basic-flex(row);
      align-items: flex-start;
      justify-content: flex-start;
      width: 82%;
      flex-wrap: wrap;
      .item{
        @include basic-flex(column);
        @include p-spacing(20px, 20px);
        align-items: flex-start;
        width: 33%;
        .top{
          img{
            width: 100%;
            border: solid 10px #f4f4f4;
            border-radius: 5px;
          }
        }
        .bottom{
          width: 100%;
          h6{
            @include h6(#333);
            text-align: left;
            font-size: 16px;
            line-height: 30px;
          }
          .line{
            width: 100%;
            border-top: dashed 3px rgba(180,146,106,.3);
            // border: solid 3px rgba(180,146,106);
            // border-radius: 3px;
          }
        }
      }
    }
  }
}
@media (max-width: 1180px){
  .common{
    .max{
      .items{
        justify-content: left;
        .item{
          width: 50%;
        }
      }
    }
  }
}
@media (max-width: 760px){
  .common{
    .max{
      .items{
        justify-content: left;
        .item{
          width: 100%;
        }
      }
    }
  }
}
@media (max-width: 540px){
  .common{
    .max{
      width: 100%;
      .items{
        width: 100%;
      }
    }
  }
}
</style>
<script>
import Expansion from '@/components/common/Expansion.vue'
export default {
  name: 'Items',
  components: {
    Expansion
  },
  data () {
    return {
      items: [
        {
          name: '逸居驗屋檢測工具說明',
          content: '<span>逸居驗屋團隊專業設備齊全，舉凡到高階的熱顯像儀、內視鏡、水分計、分貝計、室內甲醛含量檢測、雷射水準儀等等，甚至驗屋師都有專業的訓練及工程背景，幫您的房屋作最全面性的健檢，讓您在購入新屋的同時，也能更安心的居住。</span>',
          img: '../images/lost/逸居驗屋檢測工具說明.jpg'
        },
        {
          name: '熱顯像儀測漏',
          content: '<span>如圖所示，窗角、樑柱相接處、牆面轉角處常是容易產生裂縫及灌漿搗實不良處，長期來就成了漏水發生的地方，而利用熱顯像儀即可在驗屋過程中快速找出這些肉眼容易忽略之處，在交屋時事先要求建商進行處理，避免日後住進屋內還得忍受漏水的煩惱。</span>',
          img: '../images/lost/熱顯像儀側漏.jpg'
        },
        {
          name: '管線穿孔處未確實填縫',
          content: '<span>冷氣管路、排風、線路外牆穿孔處，也是驗屋常會發現的問題點，穿孔處應確實以水泥砂漿或是填縫膠填縫，一方面可避免蚊蟲從此處進到屋內，另一方面更可避免雨水倒灌進室內，如室內處恰好是施作天花板處或是系統櫃，可能導致裝潢需要拆除才能進行修繕，甚至板材可能遇水損壞更是得不償失。</span>',
          img: '../images/lost/管線穿孔處未確實填縫.jpg'
        },
        {
          name: '磁磚背面黏著劑未飽漿',
          content: '<span>如圖可見，此處磁磚師傅便宜行事，磁磚背面空縫清楚可見，表示磁磚背面未抹滿黏著劑，如此可能導致黏著性不足，日後磁磚易裂，或是掉落之虞。</span>',
          img: '../images/lost/磁磚背面黏著劑未飽漿.jpg'
        },
        {
          name: '​陽台欄杆間距或女兒牆高度不足',
          content: '<span>根據建築技術規則規定，陽台欄杆及女兒牆應不少於110公分，十層以上不得少於120公分，並且間距不得少於10公分。避免兒童攀爬墜落。<span>',
          img: '../images/lost/​陽台欄杆間距或女兒牆高度不足.jpg'
        },
        {
          name: '​​牆體水分計檢測',
          content: '<span>水分計檢測主要在測試牆體內部水分含量，若過高代表牆體內已經有滲水疑慮，即便未滲出水份，也很可能因為牆體潮濕導致壁癌產生。</span>',
          img: '../images/lost/牆體水分計檢測.jpg'
        },
        {
          name: '​天花板線路出口未套管',
          content: '<span>根據用戶用電設備裝置規則規定，電纜於地板、壁、天花板、柱等不得直接埋設，需以保護管保護。避免木工擊釘、鼠咬或施工刮破電纜外絕緣保護層，導致電纜金屬外露易造成電線短路，嚴重可能導致火災發生。</span>',
          img: '../images/lost/天花板線路出口、排風設備檢測.jpg'
        },
        {
          name: '​浴室、陽台洩水及門窗玻璃五金滲水檢測',
          content: '<span>浴室、陽台若洩水坡度未抓順或是坡度不足，會導致地面積水，若下方防水層未施作完善，則有漏水至下方住戶的疑慮。門窗五金平常風雨不大時，也很難發現滲水問題，但若五金間隙過大，或是填縫膠未施打確實，颱風天時就有屋內滲水的疑慮產生。</span>',
          img: '../images/lost/浴室、陽台洩水及門窗玻璃五金滲水檢測.jpg'
        },
        {
          name: '​水壓測試',
          content: '<span>一般高樓層住戶常見有水壓不足問題，水量過小在生活上常會感覺不便，然而水壓過大也可能造成用水器具的損壞或是給水管無法承受壓力破裂。</span>',
          img: '../images/lost/逸居驗屋檢測工具說明.jpg'
        },
        {
          name: '​天花板上牆面或樑保護層不足',
          content: '<span>天花板上常因不易檢查，施工單位就便宜行事，如圖該樑保護層厚度不足，又未施作水泥粉刷，長久以來鋼筋繡蝕導致結構強度下降。</span>',
          img: '../images/lost/天花板上牆面或樑保護層不足.jpg'
        },
        {
          name: '門、窗施工未注意，插座導致變形',
          content: '<span>如圖所示，窗戶在施做時不慎撞擊導致窗框變形，除了影響美觀外，也可能造成氣密效果減損或是窗戶開關不順。</span>',
          img: '../images/lost/門、窗施工未注意，插座導致變形.jpg'
        },
        {
          name: '玻璃厚度檢測',
          content: '<span>針對合約內容(有些建商可能在預售屋階段會提出氣密窗或隔音窗之規格)，本項檢測可針對合約內容作檢視核對。</span>',
          img: '../images/lost/玻璃厚度檢測.jpg'
        },
        {
          name: '牆面、窗框歪斜檢測',
          content: '<span>驗屋時利用雷射水準儀檢測牆體是否歪斜、窗框是否不正，避免導致日後窗戶開關不良，櫃體縫隙過大。</span>',
          img: '../images/lost/牆面歪斜檢測.jpg'
        },
        {
          name: '窗戶防墜設施測試',
          content: '<span>窗戶防墜設施開啟後開啟寬度檢查，應不超過10公分，避免孩童攀爬發生墜落意外。</span>',
          img: '../images/lost/窗戶防墜設施測試.jpg'
        },
        {
          name: '室內游離輻射檢測',
          content: '<span>檢測環境中游離輻射含量是否超標對人體健康造成影響危害。本司僅就儀器數據提供客戶參考，若有輻射疑慮請洽"行政院原子能委員會認可之輻射防護偵測業務機構"申請自費檢測。也可上原能會網站查詢是否現居為輻射屋(民國71-73年建造之房屋)<a href="https://ramdar.aec.gov.tw/">https://ramdar.aec.gov.tw/</a></span>',
          img: '../images/lost/室內游離輻射檢測.jpg'
        },
        {
          name: '​室內噪音檢測',
          content: '<span>現今建商大多標配隔音窗或是氣密窗，噪音檢測可確認窗戶氣密性是否良好。</span>',
          img: '../images/lost/室內噪音檢測.jpg'
        },
        {
          name: '​弱電系統功能測試',
          content: '<span>檢測屋內弱電系統如CCTV、電話、網路線是否線路正常。</span>',
          img: '../images/lost/弱電系統功能檢測.jpg'
        },
        {
          name: '​設備水平檢測',
          content: '<span>廁所洗手台檯面，馬桶水平檢測。</span>',
          img: '../images/lost/設備水平檢測.jpg'
        },
        {
          name: '插座電力檢測',
          content: '<span>利用漏電斷路器測試計可在驗屋時檢測漏電斷路器(根據屋內線路裝置規則規定，距離水源1.8M及浴室需裝設漏電斷路器)是否正常作動。另外也可測試電源的火線、中性線、地線是否錯接或是未接地，一般而言火性及中性線錯接，該插座還是有電，會讓使用人誤以為此插座是正常可用，但實際上大多數家電的控制開關是用來控制火線的，如果插座處的火線與零線接反了的話，會造成家電的控制開關控制的是零線，雖然這不影響電器的正常使用，但是當家電的開關在斷開的情況下，電器內部還是是帶電的，長久以來除了對電器壽命造成影響，也會增加觸電的危險。有時電腦主機殼會電人就有可能是火線及中性線接反的原因。(下圖即是實際驗屋測得火線及中性線錯接)</span>',
          img: '../images/lost/插座電力檢測.jpg'
        },
        {
          name: '磁磚、丁掛填縫未確實，敲擊空心',
          content: '<span>磁磚、丁掛填縫未確實，敲擊空心驗屋時外牆丁掛若敲擊空心，常常會導致日後外牆磁磚掉落，屆時修補不只花錢，修補完後因新舊色差，也影響建築外觀。建築物防水靠幾道關卡把關，第一層是建築物外牆的面材例如磁磚、丁掛、或是塗料；第二層是整體外牆的彈性防水材施作；第三層就是靠建築物的結構體本身防水。因此磁磚面材若填縫不實就會使得第一道關卡失效，接下來就得靠後面第二層防水層及第三層結構體來阻擋水進到室內。另外第一層面材也有保護第二層防水層的作用，避免因長期曝曬或是外力撞擊導致防水層損壞。(最下圖若磁磚敲擊空心面積過大，建議必須要請建商做更換。)</span>',
          img: '../images/lost/磁磚、丁掛填縫未確實，敲擊空心.jpg'
        }
      ]
    }
  }
}
</script>
