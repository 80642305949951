<template>
  <q-page>
    <Banner />
    <Lists />
  </q-page>
</template>
<style lang="scss" scoped>
.container{
  @include p-spacing(40px,10%);
}

@media (max-width:680px) {
  .container{
    @include p-spacing(30px, 5%);
  }
}
</style>
<script>
import Banner from '@/components/items/Banner.vue'
import Lists from '@/components/items/Lists.vue'
import { useMeta } from 'vue-meta'

export default {
  name: 'Items',
  components: {
    Banner,
    Lists
  },
  data () {
    return {}
  },
  methods: {},
  created () {
    useMeta({ title: '服務項目' })
  }
}
</script>
