<template>
    <q-page>
        <Banner />
        <Items />
    </q-page>
</template>
<style lang="scss">
.container{
  @include p-spacing(40px,10%);
}
@media (max-width:680px) {
  .container{
    @include p-spacing(30px, 5%);
  }
}
</style>
<script>
import Banner from '@/components/common/Banner.vue'
import Items from '@/components/common/Items.vue'
import { useMeta } from 'vue-meta'

export default {
  name: 'Common',
  components: {
    Banner,
    Items
  },
  data () {
    return {}
  },
  methods: {},
  created () {
    useMeta({ title: '常見缺失' })
  }
}
</script>
