<template>
  <div class="custom-block">
    <div class="title">累計完工專案</div>
    <div class="number-info">
      <div class="space"></div>
      <div
        class="number-grow"
        ref="numberGrow"
        :data-time="time"
        :data-value="number"
      >
        {{ number }}
      </div>
      <div class="unit">{{ unit }}</div>
    </div>
  </div>
</template>

<script>
import { getSetting } from '@/api/api'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
export default {
  name: 'NumberEffect',
  props: {
    time: {
      type: Number,
      default: 1.5
    },
    // number: {
    //   type: Number,
    //   default: 2031
    // },
    unit: {
      type: String,
      default: '件'
    }
  },
  data () {
    return {
      numberGrow: null,
      number: 0
    }
  },
  methods: {
    growEffect (el) {
      if (!this) return
      const step = (this.number * 10) / (this.time * 1000)
      let current = 0
      let start = 0
      let t = setInterval(() => {
        start += step
        if (start > this.number) {
          clearInterval(t)
          start = this.number
          t = null
        }
        if (current === start) {
          return
        }
        current = start
        if (el && el.innerHTML) {
          el.innerHTML = current.toFixed(0).toString()
        }
      }, 10)
    }
  },
  mounted () {
    getSetting().then(result => {
      this.number = result.caseQuantity
      gsap.registerPlugin(ScrollTrigger)
      ScrollTrigger.create({
        trigger: '.number-info',
        start: 'top 70%',
        onEnter: () => {
          this.growEffect(this.$refs.numberGrow)
        }
      })
    })
  }
}
</script>

<style lang="scss">
.custom-block{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin-top: 20px;
  margin-bottom: 30px;
  .title{
    color: #fff;
    font-weight: 500;
    font-size: 20px;
  }
  .number-info{
    display: flex;
    align-items: flex-end;
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 10px 0px 5px 0px;
    color: #fff;
    font-family: 'Noto Sans TC', sans-serif !important;
    font-weight: 900;
    .space{
      width: 22px;
    }
    .number-grow{
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 60px;
      line-height: 66px;
      margin: 0px 10px;
    }
    .unit{
      white-space: nowrap;
      font-size: 20px;
    }
  }
}
// @media(max-width: 960px){
//   .custom-block{
//     width: 100%;
//     margin-bottom: 50px;
//     .number-info{
//       .number-grow{
//         font-size: 32px;
//         margin: 0px 10px;
//       }
//     }
//   }
// }

@media(max-width: 720px){
  .custom-block{
    .number-info{
      .number-grow{
        font-size: 50px;
      }
    }
  }
}

@media(max-width: 480px){
  .custom-block{
    align-items: center;
  }
}
</style>
