<template>
  <q-page>
    <Banner />
    <div class="container blog">
      <div class="max">
        <h6>驗屋知識</h6>
        <hr>
        <div class="list">
          <router-link :to="`/post/${post.url}`" class="item" v-for="(post, index) in posts" :key="index">
            <q-responsive class="left" :ratio="100/63.47">
              <img :src="post.cover" alt=""/>
            </q-responsive>
            <div class="right">
              <h6 class="title">{{ post.title }}</h6>
              <p class="date">{{ post.publishAt }}</p>
              <p class="summary" v-html="post.summary"></p>
              <!-- <router-link class="inner-bg" :to="`/post/${post.url}`">閱讀更多 >></router-link> -->
            </div>
          </router-link>
        </div>
        <!-- <div class="q-pa-lg flex flex-center">
          <q-pagination
            v-model="current"
            :max="postsNum"
          />
        </div> -->
      </div>
    </div>
  </q-page>
  <Loading v-if="loading" />
</template>
<style lang="scss" scoped>
.blog {
  @include basic-flex(column);
  .max{
    max-width: 1500px;
    @include basic-flex(column);
    h6 {
      @include h6(#333);
      @include m-spacing(10px,0px);
    }
    hr {
      width: 50px;
      border: solid 3px rgba(180, 146, 106);
      border-radius: 3px;
    }
    .list{
      // width: 82%;
      margin-top: 30px;
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      align-items: stretch;
      .item{
        display: flex;
        flex-direction: column;
        width: calc(25% - 15px);
        justify-content: stretch;
        margin-bottom: 50px;
        text-decoration: none;
        .left{
          background-color: #eee;
          border-radius: 7px;
          position: relative;
          overflow: hidden;
          border: solid 2px #f4f4f4;
          img{
            width: 100%;
            object-fit: cover;
            min-height: 100%;
          }
        }
        .right{
          @include basic-flex(column);
          align-items: flex-start;
          a{
            text-decoration: none;
            border: solid 2px #333;
            padding: 5px 15px;
            border-radius: 3px;
            color: #000;
            letter-spacing: 1px;
            transition-duration: 0.5s;
            &:hover{
              background-color: #000;
              color: #fff;
            }
          }
          .title{
            font-size: 16px;
            line-height: 24px;
            text-align: left;
            flex: 1;
            margin: 10px 0px 3px 0px !important;
          }
          .date{
            color: #c1c1c1;
            transform: skewX(-5deg);
            letter-spacing: 1px;
            font-weight: 500;
            margin: 0px 0px 10px 0px !important;
            font-size: 13px;
          }
          .summary{
            font-size: 14px;
            letter-spacing: 1px;
            line-height: 20px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            color: #7d7d7d;
            font-weight: 500;
          }
        }
      }
    }
  }
  .q-pagination{
    :deep() .row{
      gap: 10px;
    }
  }
}
@media (max-width: 1240px){
.blog{
  .max{
    .list{
      .item{
        width: calc(33% - 14px);
      }
    }
  }
}
}
@media (max-width: 870px){
.blog{
  .max{
    .list{
      .item{
        width: calc(50% - 10px);
      }
    }
  }
}
}
@media (max-width: 480px){
.blog{
  .max{
    .list{
      gap: 10px;
      .item{
        width: calc(50% - 5px);
      }
    }
  }
}
}
</style>
<script>
import { ref } from 'vue'
import Banner from '@/components/blog/Banner.vue'
import { getPosts } from '@/api/api'
import Loading from '@/components/Loading.vue'
import { useMeta } from 'vue-meta'
import dayjs from 'dayjs'

export default {
  name: 'Blog',
  components: {
    Banner,
    Loading
  },
  data () {
    return {
      current: ref(1),
      postsNum: 0,
      posts: [],
      numLimit: 12,
      loading: true
    }
  },
  mounted () {
    this.apiStart()
    this.allPagination()
  },
  watch: {
    current: function () {
      this.apiStart()
    }
  },
  methods: {
    allPagination () {
      getPosts().then((result) => {
        const limit = this.numLimit
        console.log('[ getPosts ] result : ', result)
        const filterPosts = result.posts.filter(e => e.setting.isShow === true)
        console.log('num', filterPosts.length)
        this.postsNum = Math.ceil(filterPosts.length / limit)
      }).catch((err) => {
        console.log('[ getPosts ] err : ', err.response)
      })
    },
    apiStart () {
      // const limit = this.numLimit
      // const offset = this.current - 1
      getPosts().then((result) => {
        // console.log('[ getPosts ] result : ', result)
        const filterPosts = result.posts.filter(e => e.setting.isShow === true)
        filterPosts.forEach(e => {
          const dateInfo = dayjs.unix(e.publishAt)
          e.publishAt = dayjs(dateInfo).format('YYYY年MM月DD日')
        })
        // function group (array, groupLength) {
        //   let index = 0
        //   const newArray = []
        //   while (index < array.length) {
        //     newArray.push(array.slice(index, index += groupLength))
        //   }
        //   return newArray
        // }
        // const newPosts = group(filterPosts, limit)
        // console.log('new', newPosts)
        // this.posts = newPosts[offset]
        this.posts = filterPosts
        this.loading = false
      }).catch((err) => {
        console.log('[ getPosts ] err : ', err.response)
      })
    }
  },
  created () {
    useMeta({ title: '驗屋知識' })
  }
}
</script>
