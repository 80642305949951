<template>
<div class="banner">
  <div class="max">
    <p>無論您是不是第一次了解驗屋，都應該知道驗屋的整個流程應該包含哪些，為了保障驗屋的品質，我們都會按照客戶的案件項目來安排驗屋行程，並將每一個項目都高標準完成</p>
  </div>
</div>
</template>

<style lang="scss" scoped>
.banner{
  height: 50vh;
  width: 100%;
  background-image: url('../../assets/progress-banner.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  &::before{
    content: "";
    position: absolute;
    background-color: rgba(0,0,0,0.5);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .max{
    width: 65%;
    max-width: 1200px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 60px;
    p{
      // margin: 66px 18% 0px 18%;
      color: #fff;
      z-index: 999;
      letter-spacing: 2px;
      font-size: 14px;
      line-height: 30px;
      width: 65%;
      margin: 0;
    }
  }
}
@media (max-width: 760px){
.banner{
  .max{
    width: 80%;
    p{
      width: 100%;
    }
  }
}
}
</style>
<script>
export default {
  name: 'Banner',
  mounted: function () {
  },
  methods: {
  }
}
</script>
