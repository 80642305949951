<template>
  <q-page>
    <Banner />
    <Flow />
  </q-page>
</template>
<style lang="scss" scoped>
.container{
  @include p-spacing(40px,10%);
}

@media (max-width:680px) {
  .container{
    @include p-spacing(30px, 5%);
  }
}
</style>
<script>
import Banner from '@/components/progress/Banner.vue'
import Flow from '@/components/progress/Flow.vue'
import { useMeta } from 'vue-meta'

export default {
  name: 'Progress',
  components: {
    Banner,
    Flow
  },
  data () {
    return {}
  },
  methods: {},
  created () {
    useMeta({ title: '驗屋流程' })
  }
}
</script>
