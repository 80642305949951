<template>
  <q-page>
    <Banner />
    <Example />
  </q-page>
</template>
<style lang="scss" scoped>
.container{
  @include p-spacing(40px,10%);
}

@media (max-width:680px) {
  .container{
    @include p-spacing(30px, 5%);
  }
}
</style>
<script>
import Banner from '@/components/area/Banner.vue'
import Example from '@/components/area/Example.vue'
import { useMeta } from 'vue-meta'

export default {
  name: 'Area',
  components: {
    Banner,
    Example
  },
  data () {
    return {}
  },
  methods: {},
  created () {
    useMeta({ title: '服務案例' })
  }
}
</script>
