<template>
<div class="container process">
  <div class="max">
    <h6>驗屋流程</h6>
    <hr>
    <div class="process-block">
      <div class="step">
        <div class="number">
          <p>01</p>
        </div>
        <hr>
        <p class="item">聯繫諮詢</p>
        <p class="extra">( 電話/FB粉專/LINE )</p>
        <p class="detail">⇨ 填寫預約表單，方便客服人員與您聯繫，或是加入逸居官方LINE進行詢問</p>
      </div>
      <div class="step">
        <div class="number">
          <p>02</p>
        </div>
        <hr>
        <p class="item">資訊提供</p>
        <p class="extra">( 檔期需求/房屋資訊 )</p>
        <p class="detail">⇨ 告知欲驗屋的日期及房屋資訊，讓逸居幫您進行安排驗屋</p>
      </div>
      <div class="step">
        <div class="number">
          <p>03</p>
        </div>
        <hr>
        <p class="item">報價及檔期回覆</p>
        <p class="detail">⇨ 根據客戶需求報價及確認驗屋檔期 ( 無須支付訂金 ）</p>
      </div>
      <div class="step">
        <div class="number">
          <p>04</p>
        </div>
        <hr>
        <p class="item">確認服務細節及預約須知</p>
        <p class="detail">⇨ 確認服務細節及了解驗屋須知</p>
      </div>
      <div class="step">
        <div class="number">
          <p>05</p>
        </div>
        <hr>
        <p class="item">確認預約</p>
        <p class="extra">( 預約表單/訂金匯款 )</p>
        <p class="detail">⇨ 確認檔期及訂金支付(訂金收付後，只可進行改期，若要取消恕不退訂)</p>
      </div>
      <div class="step">
        <div class="number">
          <p>06</p>
        </div>
        <hr>
        <p class="item">完成預約資料準備</p>
        <p class="detail">⇨ 請先準備好物件資料(平面圖、水電圖各複印一份，及合約書正本)，驗屋當天一起帶至現場交給工程師進行核對。</p>
      </div>
      <div class="step">
        <div class="number">
          <p>07</p>
        </div>
        <hr>
        <p class="item">到場驗屋</p>
        <p class="extra">( 現況驗收檢測 )</p>
        <p class="detail">⇨ 現場進行驗屋，驗屋耗時一般大樓約2-3hr，透天約6-8hr</p>
      </div>
      <div class="step">
        <div class="number">
          <p>08</p>
        </div>
        <hr>
        <p class="item">驗屋完成</p>
        <p class="extra">( 缺失會勘/尾款給付 )</p>
        <p class="detail">⇨ 檢驗當天與屋主及賣方確認缺失及位置，逸居會同步進行記錄，當日結束後收取尾款</p>
      </div>
      <div class="step">
        <div class="number">
          <p>09</p>
        </div>
        <hr>
        <p class="item">驗屋報告</p>
        <p class="extra">( 電子檔 )</p>
        <p class="detail">⇨ 以電子檔交付完整驗屋報告書(7個工作日，不含例假日)</p>
      </div>
    </div>
  </div>
</div>
</template>
<style lang="scss" scoped>
.process{
  @include basic-flex(column);
  .max{
    max-width: 1500px;
    @include basic-flex(column);
    h6{
      @include h6(#333);
      @include m-spacing(10px,0px);
    }
    hr{
      width: 50px;
      border: solid 3px rgba(180,146,106);
      border-radius: 3px;
    }
    .process-block{
      @include basic-flex(row);
      @include m-spacing(20px,0px);
      width: 88%;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
      .step{
        @include basic-flex(column);
        @include m-spacing(30px,4%);
        align-items: flex-start;
        flex: 22%;
        hr{
          @include m-spacing(15px,0%);
          width: 100%;
          border: dashed 1px rgba(180,146,106,0.3);
          border-radius: 5px;
        }
        .number{
          @include basic-flex(column);
          @include m-spacing(10px,0%);
          background-color: rgba(180,146,106,0.3);
          width: 100%;
          height: 50px;
          border-radius: 10px 10px 10px 10px;
          position: relative;
          &::after{
            content: '';
            border: solid 3px rgba(180,146,106,0.3);
            width: calc(100% + 15px);
            height: calc(100% + 15px);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            border-radius: 15px 15px 15px 15px;
          }
          p{
            @include h6(#333);
            font-size: 24px !important;
          }
        }
        .item{
          @include h6(#333);
          line-height: 20px;
          font-size: 18px;
        }
        .extra{
          @include p(rgba(180,146,106));
          margin: 15px 0px 0px 0px !important;
          line-height: 10px;
        }
        .detail{
          width: 100%;
          margin: 15px 0px 0px 0px !important;
          letter-spacing: 1px;
        }
      }
    }
  }
}

@media (max-width: 1240px) {
  .process{
    .max{
      .process-block{
        .step{
          flex: 42%;
          max-width: 42%;
        }
      }
    }
  }
}
@media (max-width: 760px) {
  .process{
    .max{
      width: 100%;
      .process-block{
        @include m-spacing(10px,0px);
        .step{
          flex: 100%;
          max-width: 100%;
          .number{
            &::after{
              opacity: 0;
            }
          }
        }
      }
    }
  }
}
</style>
